@import "forms";

.textarea {
  @include border;
  padding: 12px;
  white-space: normal;
}

.editor {
  @include border;
  @include padding;
  font-family: var(--font-family-monospace);
  overflow: auto;
}

@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);

.container {
  position: absolute;
  top: 0px;
  left: 0px;
}

.legend {
  z-index: 20;
  position: absolute;
  top: 10px;
  left: -110px;
  width: 200px;
  border-top: 1px solid #5850b4dc;
  border-bottom: 1px solid #5850b4dc;
  text-align: center;
  text-justify: center;
  font-family: "Chakra Petch";
  color: #ffffff;
  font-size: 11px;
  font-weight: 600;
  font-stretch: condensed;
  background-color: #5850b477;
  padding: 4px;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.component {
  position: relative;
  top: 10px;
  left: 10px;
  // margin-bottom: 3em;
  // height: 15em;
  font-family: "Chakra Petch";
}

.cnButton {
  position: absolute;
  top: -1000px;
  left: -1300px;
  z-index: 14;
  width: 3440px;
  height: 1900px;
  background-color: #000;
  opacity: 0.2;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAXCAYAAADz/ZRUAAAAAXNSR0IArs4c6QAAAzFJREFUSEuF1tvLZ1MYB/DPk5KihlshNS5cSJNzjBmnkkNOY8bhSvkDXLoYNaUZiXAhLuTCod5BXs0YRSKkRpHD3NHQECbTXLw3jAuxWPu49tq/32vXr71/az/reb7rWd/vd+2wzhVBStOAQD/UPpcjY+zC0WIwP+ZffV2KncRe0tv4cxm+IdfSSrlAagC293yNwW3xyeRmvT/ja1yO9/FG8G7ir0l4XbT/P0M1Deyh5HvTx+Y29vkp4jvSy7gF9+AqvIe9xEekf+a4iZTzdPnyw7BJ8/7lcrN24DI8JtzQJGurnCK5DTtwEd7pgHy2qMCwjmX1O4AzSM1E8ZNkE2ltAadOxe3EDtL52NcCcSjHDqsfgbfdLQg8EG4JX58I6cfE8xN08+DTiDtJeWvOwltYwffrKmlk+4wped7FeJrYOrS1kkpeZSvFQXQbEne1HInTI9KbKVkRjjZAem51qCqp9T0bMP+AK3GsVvD6VGqiz8C9eIj4hYYvJ0bJ9TpfLtg9+A3PVi3cjg1YxVpO0yetQGWpHhDuxwcNc1tZNfED2/sWVIZ2IZ4Trm70M+rieOJzbMEnnSHtE/4olJW37QDu62IKP2w7HFMLLXEPcA6L2CylY0X5VdILwaeJm7EN1xIfk1aIX0n7O1ke7BZbKbK1mMq8Z4b+qLAmxTMFY+7GrXigmHwybsxAQlxD2p74YmqftdOtp4f23QV4Mbhi2JLWcI4I50jZckfDnB41lTw6ZeT97jWyuHxpuxzGdcie31+vYH+w2ubsO7ZIB9NkI+lm6ppPDnYlTuDJovhNeDDIWzC36DpNoahRGQv9peaB8ySvEZcUp/lJIh2VbCR+nyVsLbpNVHwUtBuUOzW8bWMy9VP99TDgiEOkO4QjhVO9hA/xasXa2Wm26ACbs71nQseuols7I/k78XhXKHMgF8/3qY8vPcmmSpoU/58dOLszjU3YjNeD6xPfLlt1mW/kRRfdHqlLvG1sd9fCJtWXndXu7nT+zSysH6j8avguKCa0lSdyLK1/psKH8Qi24qtFdcYZVR8rtZXLnmt9tm/NwLk4Ewc7ik6INbHqmYSrT8X/EvwLfr4yNOg2NgwAAAAASUVORK5CYII=)
      5 5,
    auto;
}

.csstransforms .cnWrapper {
  position: relative;
  top: -222px;
  left: -26px;
  z-index: 15;
  border-radius: 50%;
  background: transparent;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0.3s;
  -moz-transition: all 0.3s ease 0.3s;
  transition: all 0.3s ease 0.3s;
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.1);
  -moz-transform: scale(0.1);
  transform: scale(0.01);
  pointer-events: none;
}

/*cover to prevent extra space of anchors from being clickable*/
.csstransforms .cnWrapper:after {
  content: ".";
  display: block;
  font-size: 2em;
  width: 6.2em;
  height: 6.2em;
  position: absolute;
  left: 50%;
  margin-left: -3.1em;
  top: 50%;
  margin-top: -3.1em;
  border-radius: 50%;
  z-index: 10;
  color: transparent;
}

.csstransforms .openedNavMedium {
  border-radius: 50%;
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  pointer-events: auto;
}

.csstransforms .openedNavLarge {
  border-radius: 50%;
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1.2);
  pointer-events: auto;
}

.csstransforms .cnWrapper li {
  position: absolute;
  top: 72px;
  left: -97px;
  overflow: hidden;
  margin-top: 0px;
  margin-left: 0px;
  width: 10em;
  height: 10em;
  font-size: 0.8em;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: rotate(75deg) skew(50deg);
  -moz-transform: rotate(75deg) skew(50deg);
  -ms-transform: rotate(75deg) skew(50deg);
  transform: rotate(75deg) skew(50deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  pointer-events: none;
}

.csstransforms .cnWrapper li a {
  position: absolute;
  right: -7.25em;
  bottom: -7.25em;
  display: block;
  width: 14.5em;
  height: 14.5em;
  border-radius: 100%;
  background: #ccccccee;
  background: -webkit-radial-gradient(transparent 40%, #ccccccee 35%);
  background: -moz-radial-gradient(transparent 40%, #ccccccee 35%);
  background: radial-gradient(transparent 40%, #ccccccee 35%);
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 1.2em;
  line-height: 2;
  -webkit-transform: skew(-50deg) rotate(-70deg) scale(1);
  -moz-transform: skew(-50deg) rotate(-70deg) scale(1);
  -ms-transform: skew(-50deg) rotate(-70deg) scale(1);
  transform: skew(-50deg) rotate(-70deg) scale(1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  pointer-events: auto;
}

.csstransforms .cnWrapper li a span {
  position: relative;
  top: 1em;
  display: block;
  font-size: 0.5em;
  font-weight: 700;
  text-transform: uppercase;
}

.csstransforms .cnWrapper li a:hover,
.csstransforms .cnWrapper li a:active,
.csstransforms .cnWrapper li a:focus {
  background: -webkit-radial-gradient(transparent 40%, #5850b4 35%);
  background: -moz-radial-gradient(transparent 40%, #5850b4 35%);
  background: radial-gradient(transparent 40%, #5850b4 40%);
}

.csstransforms .openedNav li,
.csstransforms .openedNavSmall li,
.csstransforms .openedNavMedium li,
.csstransforms .openedNavLarge li {
  -webkit-transition: all 0.3s ease 0.3s;
  -moz-transition: all 0.3s ease 0.3s;
  transition: all 0.3s ease 0.3s;
}

// .csstransforms .openedNav li:first-child {
// 	-webkit-transform: skew(62deg);
// 	-moz-transform: skew(62deg);
// 	-ms-transform: skew(62deg);
// 	transform: skew(62deg);
// }
.csstransforms .openedNav li:nth-child(1),
.csstransforms .openedNavSmal li:nth-child(1),
.csstransforms .openedNavMedium li:nth-child(1),
.csstransforms .openedNavLarge li:nth-child(1) {
  -webkit-transform: rotate(-10deg) skew(50deg);
  -moz-transform: rotate(-10deg) skew(50deg);
  -ms-transform: rotate(-10deg) skew(50deg);
  transform: rotate(-10deg) skew(50deg);
}

.csstransforms .openedNav li:nth-child(2),
.csstransforms .openedNavSmal li:nth-child(2),
.csstransforms .openedNavMedium li:nth-child(2),
.csstransforms .openedNavLarge li:nth-child(2) {
  -webkit-transform: rotate(32deg) skew(50deg);
  -moz-transform: rotate(32deg) skew(50deg);
  -ms-transform: rotate(32deg) skew(50deg);
  transform: rotate(32deg) skew(50deg);
}

.csstransforms .openedNav li:nth-child(3),
.csstransforms .openedNavSmal li:nth-child(3),
.csstransforms .openedNavMedium li:nth-child(3),
.csstransforms .openedNavLarge li:nth-child(3) {
  -webkit-transform: rotate(74deg) skew(50deg);
  -moz-transform: rotate(74deg) skew(50deg);
  -ms-transform: rotate(74deg) skew(50deg);
  transform: rotate(74deg) skew(50deg);
}

.csstransforms .openedNav li:nth-child(4),
.csstransforms .openedNavSmal li:nth-child(4),
.csstransforms .openedNavMedium li:nth-child(4),
.csstransforms .openedNavLarge li:nth-child(4) {
  -webkit-transform: rotate(116deg) skew(50deg);
  -moz-transform: rotate(116deg) skew(50deg);
  -ms-transform: rotate(116deg) skew(50deg);
  transform: rotate(116deg) skew(50deg);
}

.csstransforms .openedNav li:nth-child(5),
.csstransforms .openedNavSmal li:nth-child(5),
.csstransforms .openedNavMedium li:nth-child(5),
.csstransforms .openedNavLarge li:nth-child(5) {
  -webkit-transform: rotate(158deg) skew(50deg);
  -moz-transform: rotate(158deg) skew(50deg);
  -ms-transform: rotate(158deg) skew(50deg);
  transform: rotate(158deg) skew(50deg);
}

.csstransforms .openedNav li:nth-child(6),
.csstransforms .openedNavSmal li:nth-child(6),
.csstransforms .openedNavMedium li:nth-child(6),
.csstransforms .openedNavLarge li:nth-child(6) {
  -webkit-transform: rotate(200deg) skew(50deg);
  -moz-transform: rotate(200deg) skew(50deg);
  -ms-transform: rotate(200deg) skew(50deg);
  transform: rotate(200deg) skew(50deg);
}

.csstransforms .openedNav li:nth-child(7),
.csstransforms .openedNavSmal li:nth-child(7),
.csstransforms .openedNavMedium li:nth-child(7),
.csstransforms .openedNavLarge li:nth-child(7) {
  -webkit-transform: rotate(242deg) skew(50deg);
  -moz-transform: rotate(242deg) skew(50deg);
  -ms-transform: rotate(242deg) skew(50deg);
  transform: rotate(242deg) skew(50deg);
}

.csstransforms .openedNav li:nth-child(8),
.csstransforms .openedNavSmal li:nth-child(8),
.csstransforms .openedNavMedium li:nth-child(8),
.csstransforms .openedNavLarge li:nth-child(8) {
  -webkit-transform: rotate(284deg) skew(50deg);
  -moz-transform: rotate(284deg) skew(50deg);
  -ms-transform: rotate(284deg) skew(50deg);
  transform: rotate(284deg) skew(50deg);
}
.no-csstransforms .cnWrapper {
  overflow: hidden;
  margin: 10em auto;
  padding: 0.5em;
  text-align: center;
}

.no-csstransforms .cnWrapper ul {
  display: inline-block;
}

.no-csstransforms .cnWrapper li {
  float: left;
  width: 5em;
  height: 5em;
  background-color: #fff;
  text-align: center;
  font-size: 1em;
  line-height: 5em;
}

.no-csstransforms .cnWrapper li a {
  display: block;
  width: 100%;
  height: 100%;
  color: inherit;
  text-decoration: none;
}

.no-csstransforms .cnWrapper li a:hover,
.no-csstransforms .cnWrapper li a:active,
.no-csstransforms .cnWrapper li a:focus {
  background-color: #f8f8f8;
}

.no-csstransforms .cnWrapper li.active a {
  background-color: #6f325c;
  color: #fff;
}

.no-csstransforms .cnButton {
  display: none;
}

@media only screen and (max-width: 620px) {
  .no-csstransforms li {
    width: 4em;
    height: 4em;
    line-height: 4em;
  }
}

@media only screen and (max-width: 500px) {
  .no-ccstransforms .cnWrapper {
    padding: 0.5em;
  }

  .no-csstransforms .cnWrapper li {
    width: 4em;
    height: 4em;
    font-size: 0.9em;
    line-height: 4em;
  }
}

@media only screen and (max-width: 480px) {
  .csstransforms .cnWrapper {
    font-size: 0.68em;
  }

  .cn-button {
    font-size: 1em;
  }
}

@media only screen and (max-width: 420px) {
  .no-csstransforms .cnWrapper li {
    width: 100%;
    height: 3em;
    line-height: 3em;
  }
}

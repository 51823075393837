.troop {
  float: inline;
  padding: 40px;
  margin-left: 46px;
  width: 600px;
  height: 380px;
  background-color: #5850b410;
  color: #f5a23c;
  margin-bottom: 8px;
}

.unitImg {
  width: 62px;
}

.unit {
  float: left;
  border: 1px solid #5850b46c !important;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 42px;
  width: 98px;
  height: 90px;
  color: #5850b4 !important;
  margin: 2px;
  cursor: pointer;
  user-select: none;
}

.quantity {
  position: relative;
  top: 0px;
  background-color: #5850b4;
  color: #d1d1d1;
  border-radius: 5px;
  text-align: center;
  width: 72px;
  cursor: pointer;
}

.button {
  border-radius: 0;
  margin: 0px !important;
  width: 30px !important;
  min-width: 20px !important;
  padding: 0px !important;
}

.control {
  position: relative;
  top: -70px;
  left: 54px;
}

.actions {
  width: 650px;
  text-align: right;
}

.actions button {
  margin: 10px;
}

.options {
  position: relative;
  top: 2px;
  margin-left: 46px;
  width: 600px;
  background-color: #5850b410;
  text-align: right;
}

.inputCustom {
  width: 66px;
  height: 24px;
  font-size: 12px !important;
}

.alertMessage {
  color: #5850b4;
  font-size: 12px;
  padding: 30px;
  text-align: center;
  width: 600px;
}

.container {
  display: flex;
}

.contentCol {
  position: absolute;
  left: 350px;
  width: 300px;
}

.listCol {
  overflow-y: auto;
  overflow-x: hidden;
  width: 350px;
  height: 460px;
}

.arrowUp {
  position: relative;
  top: 10px;
  left: 300px;
  color: #5850b4;
  cursor: pointer;
}

.arrowDown {
  position: relative;
  top: 400px;
  left: -20px;
  color: #5850b4;
  cursor: pointer;
}

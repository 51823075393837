.label {
  z-index: 20;
  position: absolute;
  top: 40px;
  left: -16px;
  width: 130px;
  text-align: center;
  padding: 2px;
  font-size: 12px;
  font-weight: 600;
  border-top: 1px solid #5850b4e5;
  border-bottom: 1px solid #5850b4e5;
  background-color: #5850b44d;
  animation: 0.5s show;
}

.labelOnConstruction {
  z-index: 20;
  position: absolute;
  top: 14px;
  left: -16px;
  width: 130px;
  text-align: center;
  padding: 2px;
  font-size: 12px;
  font-weight: 600;
  border-top: 1px solid #5850b4e5;
  border-bottom: 1px solid #5850b4e5;
  background-color: #5850b44d;
  animation: 0.5s show;
}

.labelColonization {
  z-index: 20;
  position: absolute;
  top: 74px;
  left: -10px;
  width: 130px;
  text-align: center;
  padding: 2px;
  font-size: 12px;
  font-weight: 600;
  border-top: 1px solid #5850b4e5;
  border-bottom: 1px solid #5850b4e5;
  background-color: #5850b44d;
  animation: 0.5s show;
}

.level {
  background-color: #5850b4e1;
  //border-radius: 30%;
  padding: 8px;
  position: relative;
  left: 4px;
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 25%, 75% 0%);
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.starMap {
  width: 2440px;
  height: 1900px;
  // background-image: url("/styles/images/maps/exp.jpg");
  // background-repeat: repeat-x;
  background-color: #0a0915;
  //background-repeat: repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAXCAYAAADz/ZRUAAAAAXNSR0IArs4c6QAAAzFJREFUSEuF1tvLZ1MYB/DPk5KihlshNS5cSJNzjBmnkkNOY8bhSvkDXLoYNaUZiXAhLuTCod5BXs0YRSKkRpHD3NHQECbTXLw3jAuxWPu49tq/32vXr71/az/reb7rWd/vd+2wzhVBStOAQD/UPpcjY+zC0WIwP+ZffV2KncRe0tv4cxm+IdfSSrlAagC293yNwW3xyeRmvT/ja1yO9/FG8G7ir0l4XbT/P0M1Deyh5HvTx+Y29vkp4jvSy7gF9+AqvIe9xEekf+a4iZTzdPnyw7BJ8/7lcrN24DI8JtzQJGurnCK5DTtwEd7pgHy2qMCwjmX1O4AzSM1E8ZNkE2ltAadOxe3EDtL52NcCcSjHDqsfgbfdLQg8EG4JX58I6cfE8xN08+DTiDtJeWvOwltYwffrKmlk+4wped7FeJrYOrS1kkpeZSvFQXQbEne1HInTI9KbKVkRjjZAem51qCqp9T0bMP+AK3GsVvD6VGqiz8C9eIj4hYYvJ0bJ9TpfLtg9+A3PVi3cjg1YxVpO0yetQGWpHhDuxwcNc1tZNfED2/sWVIZ2IZ4Trm70M+rieOJzbMEnnSHtE/4olJW37QDu62IKP2w7HFMLLXEPcA6L2CylY0X5VdILwaeJm7EN1xIfk1aIX0n7O1ke7BZbKbK1mMq8Z4b+qLAmxTMFY+7GrXigmHwybsxAQlxD2p74YmqftdOtp4f23QV4Mbhi2JLWcI4I50jZckfDnB41lTw6ZeT97jWyuHxpuxzGdcie31+vYH+w2ubsO7ZIB9NkI+lm6ppPDnYlTuDJovhNeDDIWzC36DpNoahRGQv9peaB8ySvEZcUp/lJIh2VbCR+nyVsLbpNVHwUtBuUOzW8bWMy9VP99TDgiEOkO4QjhVO9hA/xasXa2Wm26ACbs71nQseuols7I/k78XhXKHMgF8/3qY8vPcmmSpoU/58dOLszjU3YjNeD6xPfLlt1mW/kRRfdHqlLvG1sd9fCJtWXndXu7nT+zSysH6j8avguKCa0lSdyLK1/psKH8Qi24qtFdcYZVR8rtZXLnmt9tm/NwLk4Ewc7ik6INbHqmYSrT8X/EvwLfr4yNOg2NgwAAAAASUVORK5CYII=)
      5 5,
    auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

// @keyframes spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(180deg);
//   }
// }
// @-webkit-keyframes spin {
//   from {
//     -webkit-transform: rotate(0deg);
//   }
//   to {
//     -webkit-transform: rotate(180deg);
//   }
// }

.imageMap {
  position: absolute;
}

@media only screen and (max-width: 2560px) and (max-height: 1440px) {
  .starMap {
    top: -180px;
  }
}

@media only screen and (max-width: 1366px) and (max-height: 768px) {
  .starMap {
    top: -180px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 940px) {
  .starMap {
    top: -380px;
    left: -500px;
  }
}

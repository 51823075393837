.ships img {
  width: 84px;
}

.image {
  padding: 12px;
}

.image:hover {
  cursor: pointer;
  background-color: #061f42;
  border-radius: 10px;
  z-index: 1;
}

.disabled {
  padding: 12px;
  filter: brightness(0) contrast(0) saturate(0) invert(1);
}

.qtd {
  padding: 0px;
  border: #5850b4 1px solid;
  border-radius: 3px;
  font-size: 11px;
  width: 70px;
  text-align: center;
  position: relative;
  left: 18px;
  top: -10px;
  z-index: 2;
}

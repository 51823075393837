.top {
  padding: 6px;
}

.text {
  position: absolute;
  left: 20px;
  top: 100px;
  font-size: 14px;
  font-weight: bold;
  color: #5850b4;
}

.timeChangeMode {
  position: absolute;
  left: 20px;
  top: 80px;
  font-size: 14px;
  font-weight: bold;
  color: #5850b4;
}

.timer div {
  position: relative;
  top: -21px;
  left: 120%;
  color: #fff;
  font-weight: 600;
  background-color: #5850b4;
  border-radius: 5px;
  padding: 2px;
  width: 60px;
  font-size: 11px;
  text-align: center;
}

.changeSelected {
  --color: white;
  --background-color: #000000;
  --border-color: #5850b4;
  --border-width: 1px;
  --border-cut: 8px;
}

.button {
  position: relative;
  height: 34px !important;
  width: 120px !important;
  background-color: #000000c4;
  border: 0;
  margin: 8px;
  cursor: pointer;
  color: #f1f1f1;
  text-align: center;

  &:hover {
    background-color: #5850b4;
    color: #f1f1f1;
  }

  clip-path: polygon(0 10px, 0 34px, 140px 34px, 150px 24px, 150px 0, 10px 0);
}

.active {
  position: relative;
  height: 34px !important;
  width: 120px !important;
  border: 0;
  margin: 8px;
  cursor: pointer;
  text-align: center;
  background-color: #5850b4;
  color: #f1f1f1;
  clip-path: polygon(0 10px, 0 34px, 140px 34px, 150px 24px, 150px 0, 10px 0);
}

.researchBlock {
  position: relative;
  background-color: #0000008c;
  width: 350px;
  height: 500px;
  left: -10px;
  top: 40px;
}

.classification {
  position: relative;
  left: 40px;
  top: 50px;
  width: 650px;
  color: white;
}

.effect {
  font-size: 13px;
  font-weight: bold;
  color: #5850b4;
  padding: 12px;
  margin-top: 4px;
}

.nftList {
  position: relative;
  left: 100px;
  top: 100px;
}

.textScientist {
  color: #d1d1d1;
  font-size: 12px;
  max-width: calc(90% - 120px);
  text-align: justify;
  padding: 10px;
}

.ranking {
  position: relative;
  left: -50px;
  top: 40px;
  width: 650px;
  color: white;
}

.points {
  position: absolute;
  text-align: right;
  color: #d1d1d1;
  width: 600px;
}

.points div {
  font-size: 18px;
  font-weight: 600;
  color: #5850b4;
}

.planet {
  position: relative;
  z-index: 25;
  width: 100px;
  text-align: center;
  color: #d1d1d1;
  font-size: 11px;
  cursor: pointer;
}

.planet:hover {
  cursor: pointer;
  color: #fff;
  filter: brightness(130%);
}

.planet img {
  width: 40px;
  cursor: pointer;
}

.planet img:hover {
  width: 40px;
  cursor: pointer;
}

.contentBlock {
  position: absolute;
  top: 140px;
  left: 0;
  width: 600px;
  height: 100%;
  padding: 20px;
}

.imgNFT {
  width: 80px;
  margin: 8px;
  float: left;
  border: #333333 1px solid;
}

.imgNFTSelected {
  width: 80px;
  margin: 8px;
  float: left;
  border: #5850b4 1px solid;
}

.imgNFT:hover {
  border: #5850b4 1px solid;
  cursor: pointer;
}

@media only screen and (max-width: 1366px) {
  .line {
    display: none;
  }
}

@media only screen and (max-width: 1180px) {
  .line {
    display: none;
  }
}

@use "sass:math";
@import "mixins";

@mixin size($min-width, $height) {
  border-radius: math.div($height, 2);
  min-width: $min-width;
  height: $height;
}

.button {
  @include inline-flex;
  @include size(150px, 48px);

  font-weight: var(--bold);
  gap: 5px;
  line-height: 1;
  padding-left: 20px;
  padding-right: 20px;
  white-space: pre;

  &:hover {
    text-decoration: none;
  }
}

.disabled,
.loading {
  @include disabled;
}

/* size */
.small {
  @include size(100px, 30px);
  font-size: 14px;
}

.medium {
  @include size(130px, 35px);
  font-size: 16px;
}

.large {
  @include size(150px, 40px);
  font-size: 18px;
}

/* colors */
$default-color: var(--button-default-color);
$primary-color: var(--button-primary-color);
$danger-color: var(--button-danger-color);
$configurable-color: var(--button-configurable-color);

@mixin variant($bg-h, $bg-s, $bg-l, $color) {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #5850b4;
  border-radius: 2px;
  margin: 2px;
  color: #5850b4;
  transition: background var(--transition);
  min-width: 50px;

  &:not(.configurable):hover:not(:disabled) {
    background: #5850b4;
    border: 1px solid #5850b4;
    color: #ffffff;
  }
}

$colors: (
  default: (
    bg-h: var(--button-default-bg-h),
    bg-s: var(--button-default-bg-s),
    bg-l: var(--button-default-bg-l),
    color: $default-color,
  ),
  primary: (
    bg-h: var(--button-primary-bg-h),
    bg-s: var(--button-primary-bg-s),
    bg-l: var(--button-primary-bg-l),
    color: $primary-color,
  ),
  danger: (
    bg-h: var(--button-danger-bg-h),
    bg-s: var(--button-danger-bg-s),
    bg-l: var(--button-danger-bg-l),
    color: $danger-color,
  ),
  configurable: (
    bg-h: var(--button-configurable-bg-h),
    bg-s: var(--button-configurable-bg-s),
    bg-l: var(--button-configurable-bg-l),
    color: $configurable-color,
  ),
);

@each $color, $value in $colors {
  .#{$color} {
    @if $color == "configurable" {
      @include variant(
        map-get($value, bg-h),
        map-get($value, bg-s),
        map-get($value, bg-l),
        map-get($value, color)
      );
    } @else {
      @include variant(
        map-get($value, bg-h),
        map-get($value, bg-s),
        map-get($value, bg-l),
        map-get($colors, $color, color)
      );
    }
  }
}

/* outline */
.outline {
  border: 1px solid currentColor;
  color: currentColor;
}

/* block */
.block {
  width: 100%;
}

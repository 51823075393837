@import "mixins";

.open {
  @include flex;
  z-index: 1400;
  position: absolute;
  width: 600px;
  height: 30px;
  bottom: 0px;
  background-color: #5850b4;
  clip-path: polygon(
    0px 16px,
    0px 30px,
    600px 30px,
    600px 16px,
    584px 0px,
    16px 0px
  );
  font-weight: 600;
  cursor: pointer;
  user-select: none;
}

.navigator {
  @include flex;
  position: absolute;
  left: 300px;
}

.content {
  background-color: transparent;
  width: 550px;
  height: 300px;
  background-image: url("/styles/images/shapes/shapeSpaceWarp.svg");
  padding: 30px;
}

select {
  height: 19px !important;
  border: none !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin: 0px !important;
}

.label {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
}

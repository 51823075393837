.content {
  position: relative;
  bottom: 150px;
  right: 60px;
  width: 450px;
  vertical-align: top;
  margin: 2px;
  padding: 10px;
  color: #5850b4;
  overflow: auto;
}

.contentInative {
  position: relative;
  bottom: 100px;
  width: 450px;
  vertical-align: top;
  margin: 2px;
  padding: 20px;
  color: #5850b4;
  font-weight: bold;
}

.msg {
  position: relative;
  top: 150px;
  border: 1px solid #5850b46c !important;
  background-color: #5850b41c;
  text-align: center;
  padding: 20px;
  font-weight: bold;
}

.imgNFT {
  display: inline-block;
  width: 70px;
  height: 70px;
  margin-right: 10px;
  margin-bottom: -18px;
  cursor: pointer;
  border: 2px solid transparent;
}

.imgNFT.common {
  position: relative;
  bottom: 50px;
  border-color: #5850b4;
}

.imgNFT.uncommon {
  position: relative;
  bottom: 50px;
  border-color: #3e8ede;
}

.imgNFT.rare {
  position: relative;
  bottom: 50px;
  border-color: #79aa22;
}

.legend {
  position: relative;
  top: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.legendItem {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.commonDot {
  background-color: #5850b4;
}

.uncommonDot {
  background-color: #3e8ede;
}

.rareDot {
  background-color: #79aa22;
}

.pagination {
  position: relative;
  right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  white-space: nowrap;
}

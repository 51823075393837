.hsAccordion {
  position: relative !important;
  overflow: scroll !important;
  // height: 88% !important;
}

.hsContainer {
  overflow: scroll !important;
}

.hsAccordionItem {
  border-bottom: 1px solid #5850b4;
}

.hsAccordionHeader {
  background-color: transparent;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  color: #eee;
  font-weight: bold;
  width: 100%;
}
.hsLinkCancelar {
  color: #5850b4;
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 2px;
}
.hsLinkCancelar:hover {
  border-bottom: #5850b4 1px solid;
}
.accordion-content {
  display: none;
  padding: 10px;
  background-color: transparent;
}

.hsAccordionContentBody {
  display: flex;
  flex-direction: column;
  color: #eee;
  justify-content: space-between;
  align-items: flex-start;
}

.hsDiv {
  display: flex;
  padding-top: 2px;
  padding-bottom: 1px;
  width: 100%;
}

.leftDiv {
  flex-basis: 50%;
  flex-grow: 0;
  // border-right: 1px solid #5850B4;
}

.rightDiv {
  flex-basis: 50%;
  flex-grow: 0;
}

.content-text {
  width: 95%;
  height: auto;
  color: #eee;

  /* background-color: #ccc; */
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.hsContentHeader {
  padding-left: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.content-header {
  padding-left: 10px;
  width: 100%;
}

.container {
  position: absolute;
  top: 100px;
  left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 570px;
}

.timeChangeMode {
  position: absolute;
  left: 20px;
  top: 80px;
  font-size: 14px;
  font-weight: bold;
  color: #5850b4;
}

.timer div {
  position: relative;
  top: 20px;
  left: 320px;
  color: #fff;
  font-weight: 600;
  background-color: #5850b4;
  border-radius: 5px;
  padding: 10px;
  width: 80px;
  font-size: 14px;
  text-align: center;
}

.changeContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.changeTitle {
  text-align: center;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: bold;
}

.changeEffect {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  top: -70px;
  right: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #5850b4;
}

.changeImage {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  top: -30px;
  left: 260px;
}

.changeText {
  position: relative;
  top: 10px;
  text-align: center;
  margin-bottom: 25px;
}

.changeName {
  position: relative;
  top: -30px;
  right: -20px;
  text-align: center;
  margin-bottom: 25px;
}

.changeContainer {
  --color: white;
  --background-color: #000000;
  --border-color: #5850b4;
  --border-width: 1px;
  --border-cut: 8px;
}

.changeContainer {
  position: relative;
  top: 40px;
  left: 270px;
  border: 1px solid var(--border-color);
  width: 160px;
  height: 80px;
}

.changeSelected {
  position: relative;
  top: 50px;
  left: -230px;
}

.cancel {
  position: relative;
  left: 430px;
  top: 68px;
  margin-top: 30px;
}

.confirm {
  position: relative;
  left: 580px;
  margin-top: 30px;
}

.listHor {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

@media only screen and (max-width: 1180px) {
  .container {
    top: 50px;
  }
}

.strategyButton {
  float: left;
  border: 1px solid #5850b46c !important;
  padding: 4px;
  width: 40px;
  height: 40px;
  color: #5850b4 !important;
  margin: 3px;
  cursor: pointer;
  user-select: none;
  filter: grayscale(100%);
}

.strategyButtonSelected {
  float: left;
  border: 1px solid #5850b4 !important;
  background-color: #5850b411;
  padding: 4px;
  width: 40px;
  height: 40px;
  color: #5850b4 !important;
  margin: 3px;
  cursor: pointer;
  user-select: none;
}

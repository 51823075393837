.item {
  padding: 4px;
  margin: 2px;
  user-select: none;
}

.title {
  font-size: 14px;
  font-weight: 600;
  color: #5850b4;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
  margin-top: 10px;
  background-color: #00000035;
}

.subTitle {
  position: relative;
  top: 20px;
  font-size: 13px;
  font-weight: 600;
  color: #5850b4;
  text-align: left;
  padding: 8px;
  background-color: #00000035;
}

.description {
  font-size: 13px;
  color: #d1d1d1;
  text-align: justify;
  padding: 8px;
  overflow: auto;
  height: 118px;
  margin-bottom: 8px;
}

.effectDescription {
  position: relative;
  top: 20px;
  font-size: 13px;
  color: #d1d1d1;
  text-align: justify;
  padding: 8px;
}

.cost {
  font-size: 14px;
  font-weight: 600;
}

.button {
  position: relative;
  top: 20px;
  text-align: center;
}

.button button {
  width: 94%;
}

.contentImage1 {
  width: 100%;
  background-color: #26c100;
  text-align: center;
  border: #111 solid 2px;
}

.contentImage2 {
  width: 100%;
  background-color: #ce00e4;
  text-align: center;
  border: #111 solid 2px;
}

.contentImage3 {
  width: 100%;
  background-color: #00b2ff;
  text-align: center;
  border: #111 solid 2px;
}

.contentImage1 img,
.contentImage2 img,
.contentImage3 img {
  width: 278px;
}

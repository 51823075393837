.none {
  display: none;
}

.default {
  display: block;
}

.star {
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 0 0 10px #ffffff;
}

.disabled {
  pointer-events: none !important;
  cursor: default !important;
  user-select: none !important;
  filter: opacity(0.1);
}

.quadrantText {
  position: absolute;
  top: -16px;
  left: 26px;
  font-size: 12px;
  width: 300px;
  color: #fff;
  user-select: none;
  animation: open 5s;
  cursor: pointer;
}

.quadrantEffect {
  position: absolute;
  top: -15px;
  left: -15px;
  width: 30px;
  height: 30px;
  border: orange dashed 2px;
  user-select: none;
  animation: open 5s;
  cursor: pointer;
}

.playerQuadrant {
  position: absolute;
  top: -15px;
  left: -15px;
  width: 30px;
  height: 30px;
  border: orange dashed 2px;
  user-select: none;
  cursor: pointer;
}

@keyframes open {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

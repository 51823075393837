.content {
  position: relative;
  background-color: #222222ef;
  top: 0px;
  height: 610px;
  width: 851px !important;
  padding: 10px;
  border-right: #00000059 3px solid;
  overflow: scroll;
}

.bar {
  position: absolute;
  // right: 770px;
  width: 10px;
  height: 500px;
  top: 50px;
  background-color: #352f6f;
  clip-path: polygon(0 0, 0 500px, 14px 490px, 14px 10px);
  // transform: skewY(45deg);
}

@media only screen and (max-width: 1366px) and (max-height: 768px) {
  .content {
    position: relative;
    transform: translate(8%, -12%) scale(0.75);
    clip-path: polygon(
      0 16px,
      0 584px,
      16px 600px,
      784px 600px,
      784px 0,
      16px 0
    );
  }

  .bar {
    height: 360px;
    left: 170px;
  }
}

@media only screen and (max-width: 1280px) {
  .swip {
    position: absolute;
    top: -60px;
  }
  .content {
    position: relative;
    transform: translate(8%, -12%) scale(0.75);
    clip-path: polygon(
      0 16px,
      0 584px,
      16px 600px,
      784px 600px,
      784px 0,
      16px 0
    );
  }

  .bar {
    height: 360px;
    left: 170px;
  }
}

@media only screen and (max-width: 1180px) {
  .swip {
    position: absolute;
    top: -90px !important;
  }
}

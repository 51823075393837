.tbTitle {
  height: 22px;
  text-align: center;
  color: white;
  background-color: #5850b4;
  width: 118%;
  margin-left: -11px;
}
.tbSellerTitles {
  margin-top: 3px;
  height: 20px;
  width: 115%;
  margin-left: -8px;
  color: white;
  display: flex;
  font-size: 0.8em;
  cursor: pointer;
}
.tbSeller {
  width: 118%;
  margin-left: -11px;
}
.tbSellerTitles > div:hover {
  scale: 1.1;
}
.tbSeller table thead {
  display: none;
}
.tbSeller tr td {
  font-size: 12px;
  padding: 5px;
  cursor: pointer;
}
.active {
  background-color: #5850b4 !important;
}
.containerSeller {
  position: relative;
  // flex-direction: column;
  height: auto;
}

.tbTitle {
  text-align: center;
}

.mySeller {
  font-style: italic !important;
  font-weight: bold !important;
}

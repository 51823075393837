.footer {
  position: fixed;
  font-size: 11px;
  bottom: 10px;
  right: 40px;
  color: #0a0915;
  z-index: 1;
  user-select: none;
}

.footer a {
  color: #0a0915;
  text-decoration: none;
}

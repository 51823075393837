@import "mixins";

.content {
  z-index: 0 !important;
  background-color: #000000ad;
  width: 200px;
  height: 610px;
  padding: 10px;
  text-align: center;
  user-select: none;
  border: #5850b491 1px solid;
}

.planet {
  cursor: pointer;
}

.planet img {
  width: 60px;
}

.name {
  color: #5850b4;
  font-weight: 600;
}

.quadrant {
  color: #fff;
  font-size: 11px;
}

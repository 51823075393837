@import "mixins";

.component {
  padding: 30px 0;
  text-align: center;
}

.icon {
  font-size: 56px;
}

.content {
  word-break: break-all;
}

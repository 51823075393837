.item {
  padding: 8px;
  margin: 5px;
  user-select: none;
}

.lock {
  position: relative;
  top: -38px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: brightness(0) invert(1);
}

.titleL {
  font-size: 13px;
  position: relative;
  left: 30px;
  color: #f1f1f1;
  width: 100px;
  cursor: pointer;
}

.titleR {
  font-size: 13px;
  position: relative;
  left: -150px;
  color: #e0e0e0;
  text-align: right;
  width: 175px;
  cursor: pointer;
}

.titleLLock {
  font-size: 13px;
  position: relative;
  left: 30px;
  color: #f1f1f196;
  cursor: pointer;
}

.titleRLock {
  font-size: 13px;
  position: relative;
  left: -130px;
  color: #f1f1f196;
  text-align: right;
  width: 170px;
  cursor: pointer;
}

.active {
  filter: grayscale(0%) brightness(150%) contrast(115%) saturate(180%);
}

.elementL {
  position: relative;
  width: 50px;
  height: 50px;
  z-index: 1900;
  left: 15px;
  fill: "green";
  cursor: pointer;
  // clip-path: polygon(
  //   29% 0,
  //   71% 0,
  //   100% 29%,
  //   100% 71%,
  //   71% 100%,
  //   29% 100%,
  //   0 71%,
  //   0 29%
  // );
  // background-color: black;
  border-radius: 5px;
  cursor: pointer;
}

.elementR {
  position: relative;
  width: 50px;
  height: 50px;
  z-index: 10;
  left: 100px;
  cursor: pointer;
  clip-path: polygon(
    29% 0,
    71% 0,
    100% 29%,
    100% 71%,
    71% 100%,
    29% 100%,
    0 71%,
    0 29%
  );
  background-color: black;
  border-radius: 5px;
  cursor: pointer;
}

.button {
  position: relative;
  top: 80px;
  left: 10px;
}

.buttonStart {
  position: relative;
  top: 40px;
  left: 20px;
}

.imgIcon {
  filter: brightness(70%) grayscale(0.5);
}

.imgIconLock {
  filter: brightness(60%) grayscale(1);
}

.imgIconSelected {
  filter: brightness(100%);
}

.imgIconResearched {
  filter: grayscale(96%);
}

.imgIconResearchedSeleted {
  filter: grayscale(96%) brightness(160%);
}

.founder {
  position: relative;
  top: -162px;
  left: -498px;
  cursor: pointer;
}

.founder img:hover {
  filter: brightness(110%) contrast(105%) saturate(130%)
    drop-shadow(0px 0px 8px #fff);
  user-select: none;
}

.label {
  z-index: 20;
  position: relative;
  top: -172px;
  left: -440px;
  width: 130px;
  text-align: center;
  padding: 2px;
  font-size: 12px;
  font-weight: 600;
  border-top: 1px solid #5850b4e5;
  border-bottom: 1px solid #5850b4e5;
  background-color: #5850b44d;
  animation: 0.5s show;
}

.hiden {
  opacity: 0;
  z-index: 20;
  position: relative;
  top: -140px;
  left: 430px;
  width: 130px;
  text-align: center;
  padding: 2px;
  font-size: 12px;
  font-weight: 600;
  border-top: 1px solid #5850b4e5;
  border-bottom: 1px solid #5850b4e5;
  background-color: #5850b44d;
  animation: 0.5s show;
}

.box {
  font-size: 11px;
  width: 338px;
  vertical-align: top;
  border-radius: 2px !important;
  border: 1px solid #5850b46c !important;
  background-color: #5850b407;
  margin: 2px;
  padding: 20px;
  color: #5850b4;
}
.title_slot {
  position: relative;
  top: -148px;
  left: -465px;
  cursor: pointer;
  width: 7.7vw;
  height: 4.7vw;
  border-radius: 50%;
}

.title_slot_div {
  position: relative;
  top: -162px;
  left: -488px;
  width: 12vw;
  height: 1vw;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  border-top: 1px solid #5850b4e5;
  border-bottom: 1px solid #5850b4e5;
  background-color: #5850b44d;
  animation: 0.5s show;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.rewardTable tr {
  color: #f1f1f1;
  border: 1px solid #5850b409;
  padding: 10px !important;
  margin: 10px !important;
}

.rewardTable td {
  padding: 6px !important;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@use "sass:math";
@import "mixins";

.page {
  width: 100%;
  height: 100%;
  background-image: url("/styles/images/bg/bgLobby.jpg");
}

.content {
  width: 100%;
  text-align: center;
}

.block {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.message {
  text-align: left;
  margin-top: 50px;
}

.panel {
  width: 500px;
  padding: 10px;
  margin: 10px;
}

.logo {
  width: 400px;
  margin-bottom: 80px;
}

.button {
  min-width: 200px;
}

.socialLinks a {
  margin: 6px;
}

.extraActions {
  margin-top: 20px;
  text-align: right;
}

.extraActions a {
  margin: 6px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 18px;
  margin-top: 18px;
}

/* SCIFI PANEL */
.scifi {
  /* == BUTTON SETTINGS == */
  --color: white;
  // --background-color:  #0d0a11;
  --background-color: #000000ec;
  // --border-color: #0bb3b3;
  --border-color: #5850b4;
  --border-width: 1px;
  --border-cut: 8px;
  --border-corner: 12px;
  --button-shape: polygon(
    0 var(--border-corner),
    var(--border-corner) 0,
    calc(40% - var(--border-cut) - 10px) 0%,
    calc(calc(40% - var(--border-cut) - 10px) + var(--border-cut))
      var(--border-cut),
    calc(calc(60% - var(--border-cut) - 10px) + var(--border-cut) + 20px)
      var(--border-cut),
    calc(
        calc(60% - var(--border-cut) - 10px) + var(--border-cut) + 20px +
          var(--border-cut)
      )
      0,
    calc(100% - var(--border-corner)) 0,
    100% var(--border-corner),
    100% calc(40% - var(--border-cut) - 5px),
    calc(100% - var(--border-cut))
      calc(40% - var(--border-cut) - 5px + var(--border-cut)),
    calc(100% - var(--border-cut))
      calc(60% - var(--border-cut) - 5px + var(--border-cut) + 10px),
    100%
      calc(
        60% - var(--border-cut) - 5px + var(--border-cut) + 10px +
          var(--border-cut)
      ),
    100% calc(100% - var(--border-corner)),
    calc(100% - var(--border-corner)) 100%,
    calc(
        calc(60% - var(--border-cut) - 10px) + var(--border-cut) + 20px +
          var(--border-cut)
      )
      100%,
    calc(calc(60% - var(--border-cut) - 10px) + var(--border-cut) + 20px)
      calc(100% - var(--border-cut)),
    calc(calc(40% - var(--border-cut) - 10px) + var(--border-cut))
      calc(100% - var(--border-cut)),
    calc(calc(40% - var(--border-cut) - 10px)) 100%,
    var(--border-corner) 100%,
    0 calc(100% - var(--border-corner)),
    0
      calc(
        calc(60% - var(--border-cut) - 5px) + var(--border-cut) + 10px +
          var(--border-cut)
      ),
    var(--border-cut)
      calc(calc(60% - var(--border-cut) - 5px) + var(--border-cut) + 10px),
    var(--border-cut)
      calc(calc(40% - var(--border-cut) - 5px) + var(--border-cut)),
    0 calc(calc(40% - var(--border-cut) - 5px))
  );
}

.overlay {
  @include flex;
  background: rgba(0, 0, 0, 0.582);

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 1050;
}

.close {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 20px;
  height: 40px;
  width: 40px;
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 30%, 70% 0%);
  background-color: var(--button-primary-bg);
  margin: 16px;
}

.close span {
  position: relative;
  top: 4px;
  left: -2px;
}

.header {
  height: 40px;
  width: 100%;
  background-color: #111111;
  position: relative;
  left: 0px;
  padding-left: 40px;
  padding-top: 10px;
}

.twitter {
  position: relative;
  top: 50px;
}

.icon {
  font-size: 56px;
  line-height: 1;
}

.title {
  font-size: 14px;
}

.confirm {
  font-size: inherit;
}

.main {
  overflow: hidden; // Sub components declare their own max-height and overflow-y
  position: relative;
  top: -20px;
  width: 500px;
  vertical-align: top;
}

.scifi {
  color: var(--color) !important;
  border: 0;
  display: grid;
  gap: 32px;
  align-items: center;
  justify-content: center;
  justify-self: center;
  align-self: center;
  appearance: none;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  word-spacing: 3px;
  letter-spacing: 0.4px;
  transition: 0.3s;
  line-height: initial;
  // white-space: nowrap;
  z-index: 1;
  outline: 0;
  background-color: transparent;

  vertical-align: top;

  @include desktop {
    padding: 30px;
    width: 600px;
  }

  @include mobile {
    padding: 48px 20px;
    width: calc(100% - 40px);
  }
}

.scifi:before,
.scifi:after {
  content: "";
  inset: 0;
  background: var(--border-color);
  position: absolute;
  z-index: -1;
  clip-path: var(--button-shape);
  box-shadow: inset 0 0 25px -10px var(--border-color);
}

.scifi:after {
  inset: var(--border-width);
  background: var(--background-color);
}

.helper1 {
  content: "";
  position: absolute;
  inset: calc(var(--border-cut) / 2);
  background: var(--border-color);
  z-index: -2;
  clip-path: polygon(
    0% 5%,
    0% 95%,
    5% 100%,
    95% 100%,
    100% 95%,
    100% 5%,
    95% 0%,
    5% 0%
  );
}

// .helper1:before,
// .helper1:after {
//   content: "";
//   position: absolute;
//   height: 35%;
//   width: 3px;
//   background: var(--border-color);
//   left: -1px;
//   top: 50%;
//   // transform: translateY(-40%);
// }

.helper1:after {
  left: unset;
  right: -1px;
}

[class*="scifi"].disabled,
[class*="scifi"]:disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
}

@media only screen and (max-width: 1720px) {
  .page {
    max-width: 100%;
    height: 100%;
    background-position: center center;
    background-image: url("/styles/images/bg/bgLobby.jpg");
  }

  .content {
    position: relative;
    top: 10px;
    left: 100px;
    width: 100%;
    text-align: center;
  }

  .block {
    position: relative;
    left: 50px;
    width: 90%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .panel {
    width: 480px;
    padding: 10px;
    margin: 10px;
  }

  .logo {
    width: 350px;
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 1640px) {
  .content {
    position: relative;
    top: 10px;
    left: 100px;
    width: 100%;
    text-align: center;
  }

  .block {
    position: relative;
    left: 50px;
    width: 90%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .panel {
    width: 480px;
    padding: 10px;
    margin: 10px;
  }

  .logo {
    width: 350px;
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 1440px) {
  .content {
    position: relative;
    left: 30px;
    top: 0px;
    width: 100%;
    text-align: center;
  }

  .block {
    position: relative;
    left: 380px;
    width: 550px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .panel {
    width: 400px;
    padding: 10px;
    margin: 10px;
  }

  .logo {
    position: relative;
    left: 100px;
    width: 300px;
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 1280px) {
  .content {
    width: 80%;
    text-align: center;
  }

  .block {
    position: relative;
    bottom: 40px;
    width: 300px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .panel {
    width: 400px;
    padding: 10px;
    margin: 10px;
  }
}
@media only screen and (max-width: 1024px) {
  .content {
    width: 80%;
    text-align: center;
  }

  .block {
    position: relative;
    bottom: 40px;
    width: 200px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .panel {
    width: 400px;
    padding: 10px;
    margin: 10px;
  }
}
.recaptcha {
  z-index: -100;
}

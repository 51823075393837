.scena {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  background-color: #000915;
  user-select: none;
  animation-name: warp;
  animation-duration: 1s;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAXAQMAAADJMwYXAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAA////pdmf3QAAAAJ0Uk5TAP9bkSK1AAAAU0lEQVR4nGNgAAF+MPEASLD/ARJs9kCCmR3IZ2b4AyLkGxgYG5gfMDC2Mx4AEo8bGBiO/weqOWYHJI6DNCcjCKAkgyGIMAYR5iAxORAhAyIkGBgAC4QQkZQqv1QAAAAASUVORK5CYII=)
      5 5,
    auto;
}

.tag {
  z-index: 20;
  height: 110px;
  position: absolute;
  top: 200px;
  left: 0px;
  color: #717171;
  font-size: 14px;
  user-select: none;
  animation-name: warp;
  animation-duration: 1s;
}

@keyframes warp {
  0% {
    filter: blur(5px);
  }
  100% {
    filter: blur(0px);
  }
}

.regionLogo {
  position: fixed;
  z-index: 2;
  top: -255px;
  left: -510px;
}

.regionLogo {
  filter: grayscale(30%);
}

@media only screen and (max-width: 1440px) {
  .tag {
    z-index: 20;
    height: 110px;
    position: absolute;
    top: 270px;
    left: 0px;
    color: #717171;
    font-size: 14px;
    user-select: none;
    animation-name: warp;
    animation-duration: 1s;
  }

  .regionLogo {
    top: -200px;
  }

  .quadrantName {
    position: relative;
    top: 70px;
  }
}

@media only screen and (max-width: 1366px) {
  .tag {
    z-index: 20;
    height: 110px;
    position: absolute;
    top: 270px;
    left: 0px;
    color: #717171;
    font-size: 14px;
    user-select: none;
    animation-name: warp;
    animation-duration: 1s;
  }

  .regionLogo {
    top: -200px;
  }

  .quadrantName {
    position: relative;
    top: -30px;
  }
}

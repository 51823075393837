.containerTrading {
  display: flex;
  flex-direction: row;

  // background-color: #ccc;
  position: absolute;
  width: 56%;
  height: 65%;
  // height: 35vh;
  // width: 30vw;
}

.tradingLeftDiv {
  color: green;
  flex-basis: 50%;
  flex-grow: 0;
  padding: 10px;
  border-right: 1px solid #5850b4;
  //  border-bottom: 1px solid #5850B4;
  overflow: scroll !important;
}

.tradingRightDiv {
  display: inline;
  color: red;
  flex-basis: 50%;
  flex-grow: 0;
  padding: 10px;
  overflow: scroll !important;

  // padding: 10px;
}

//

.containerSeller {
  display: flex;
  flex-direction: column;
  height: auto;
}

.sellerData {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 5px;
  padding-bottom: 5px;
}

.sellerDistance {
  text-align: right;
}

.sellerPrice {
  text-align: center;
}

.sellerName {
  text-align: left;
}

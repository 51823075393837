.buildImage {
  position: relative;
  width: 250px;
  left: 230px;
}

.description {
  font-size: 12px;
  text-align: justify;
  opacity: 0.7;
}

.lineDetails {
  text-align: left;
  border: 1px solid #5850b46c;
  border-radius: 2px;
  padding: 4px;
  margin: 4px;
  font-size: 15px;
  color: #f1f1f1;
}

.specialTitle {
  font-size: 13px;
  font-weight: bold;
  background-color: #5850b4;
  color: white;
  padding: 5px;
  margin-bottom: 10px;
  width: 200px;
  text-align: center;
}

.specialTitleLinks {
  position: relative;
  left: 250px;
  font-size: 13px;
  font-weight: bold;
  background-color: #5850b4;
  color: white;
  padding: 5px;
  margin-bottom: 10px;
  width: 200px;
  text-align: center;
}

.tbTitle {
  height: 22px;
  text-align: center;
  color: white;
  background-color: #5850b4;
  width: 118%;
  margin-left: -11px;
}
.tbPurchTitles {
  margin-top: 3px;
  height: 20px;
  width: 110%;
  margin-left: -6px;
  color: white;
  display: flex;
  font-size: 0.8em;
  cursor: pointer;
}

.tbPurchTitles > div:hover {
  scale: 1.1;
}
.tbPurch {
  width: 118%;
  margin-left: -11px;
}

.tbPurch table thead {
  display: none;
}

.tbPurch tr td {
  font-size: 12px;
  padding: 5px;
  cursor: pointer;
}

.containerPurch {
  position: relative;
  // flex-direction: column;
  height: auto;
}

.active {
  background-color: #5850b4 !important;
}

.myPurch {
  font-style: italic !important;
  font-weight: bold !important;
}

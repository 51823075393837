.control {
  z-index: 1900;
  width: 110px;
  height: 580px;
  position: absolute;
  right: -83px;
  top: 210px;
  transition: 0.3s;
  user-select: none;
}

.controlOpen {
  z-index: 1900;
  width: 110px;
  height: 580px;
  position: absolute;
  right: -1px;
  top: 210px;
  transition: 0.3s;
  user-select: none;
}

.arco1 {
  position: absolute;
  top: 14px;
  right: 0px;
  width: 10px;
  height: 574px;
  border-radius: 10%;
  border-left: #5850b4 5px solid;
  position: relative;
  opacity: 0.15;
  user-select: none;
}
.arco2 {
  position: absolute;
  top: -560px;
  right: 6px;
  width: 10px;
  height: 574px;
  border-radius: 10%;
  border-left: #5850b4 5px solid;
  position: relative;
  opacity: 0.1;
  user-select: none;
}
.arco3 {
  position: absolute;
  top: -1134px;
  right: 12px;
  width: 10px;
  height: 574px;
  border-radius: 10%;
  border-left: #5850b4 5px solid;
  position: relative;
  opacity: 0.05;
  user-select: none;
}

.arco4 {
  position: absolute;
  top: -1708px;
  right: 18px;
  width: 10px;
  height: 574px;
  border-radius: 10%;
  border-left: #5850b4 5px solid;
  position: relative;
  opacity: 0.015;
  user-select: none;
}

.commander {
  z-index: 1900;
  position: relative;
  top: -2250px;
  right: 0px;
  margin: 4px;
  cursor: pointer;
  user-select: none;
}

.commander img {
  z-index: 1900;
  width: 70px;
  margin-left: 10px;
  filter: grayscale(90%) contrast(110%);
  user-select: none;
}

.commander img:hover {
  filter: grayscale(0%) brightness(110%) contrast(105%) saturate(100%)
    drop-shadow(0px 0px 4px #fff) !important;
  transition: all 0.4s ease;
  user-select: none;
}

.commanderUnit {
  z-index: 1900;
  float: left;
  margin: 8px;
  user-select: none;
}

.activeCommander {
  filter: grayscale(0%) brightness(110%) contrast(105%) saturate(100%)
    drop-shadow(0px 0px 4px #fff) !important;
  transition: all 0.4s ease;
  user-select: none;
}

.elementBar {
  position: absolute;
  top: 0px;
  right: -1px;
  width: 106px;
  height: 600px;
  background-color: #222222da;
  clip-path: polygon(0 10px, 0 590px, 10px 100%, 100% 100%, 100% 0, 10px 0px);
  user-select: none;
}

.elementBarClose {
  position: absolute;
  top: 0px;
  right: -1px;
  width: 116px;
  height: 600px;
  background-color: #0a0a0a;
  clip-path: polygon(
    0 10px,
    0 48px,
    9px 55px,
    9px 536px,
    0 546px,
    0 590px,
    10px 100%,
    100% 100%,
    100% 0,
    10px 0px
  );
  transition: 0.1s;
  user-select: none;
}

.elementBarComplement {
  position: absolute;
  transition-delay: 0.1s;
  top: 46px;
  right: 97px;
  width: 10px;
  height: 600px;
  clip-path: polygon(0 0, 0 500px, 14px 490px, 14px 10px);
  background-color: #5850b4;
  user-select: none;
}

.elementBarComplementClose {
  position: absolute;
  background-color: transparent;
  top: 46px;
  right: 97px;
  width: 10px;
  height: 600px;
  clip-path: polygon(0 0, 0 500px, 14px 490px, 14px 10px);
  // background-color: #5850B4;
  user-select: none;
}

.commanderName {
  position: absolute;
  left: 21px;
  width: 70px;
  font-size: 11px;
  font-weight: 600;
  color: #fff;
  user-select: none;
  text-align: center;
}

.hide {
  display: none;
  user-select: none;
}

@media only screen and (max-width: 1366px) and (max-height: 768px) {
  .commander {
    top: -2250px;
    left: 10px;
    z-index: 1000;
  }
  .control {
    top: 430px;
    right: -103px;
    transform: translate(-50%, -50%) scale(0.75);
  }

  .controlOpen {
    top: 430px;
    right: -55px;
    transform: translate(-50%, -50%) scale(0.75);
  }
}

@media only screen and (max-width: 1280px) {
  .commander {
    top: -2250px;
    left: 10px;
    z-index: 1000;
  }
  .control {
    top: 430px;
    right: -103px;
    transform: translate(-50%, -50%) scale(0.75);
  }

  .controlOpen {
    top: 430px;
    right: -55px;
    transform: translate(-50%, -50%) scale(0.75);
  }
}

@media only screen and (max-width: 1180px) {
  .commander {
    top: -2250px;
    left: 10px;
    z-index: 1000;
  }
  .control {
    top: 430px;
    right: -103px;
    transform: translate(-50%, -50%) scale(0.75);
  }

  .controlOpen {
    top: 430px;
    right: -55px;
    transform: translate(-50%, -50%) scale(0.75);
  }
}

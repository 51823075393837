.connect {
  border-radius: 5px !important;
  border: 1px solid #5850b46c !important;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  width: 120px;
  color: #5850b4 !important;
}

.connects {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #5850b4a4;
  border-radius: 8px;
  padding: 16px;
  margin: 10px;
  width: 162px;
  color: #5850b4a4;
  cursor: pointer;
  text-align: center;
  flex-direction: column;
}

.connects:hover {
  border: 1px solid #5850b4;
  color: #5850b4;
}

.connects img {
  width: 70px;
  margin-bottom: 10px;
}

.title {
  font-size: 10px;
}

.invalid {
  border-radius: 5px !important;
  border: 1px solid red !important;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  width: 120px;
  color: red !important;
}

.connect:hover {
  border: 1px solid #5850b4 !important;
}

.title {
  font-size: 20px;
  font-weight: 600;
  padding: 20px;
  margin-bottom: 10px;
}

.dataTop {
  padding: 10px;
  border-top: #5850b42a 1px solid;
}

.dataMiddle {
  padding: 10px;
  border-top: #5850b42a 1px solid;
}

.dataFooter {
  padding: 10px;
  border-top: #5850b42a 1px solid;
  border-bottom: #5850b42a 1px solid;
}

.address {
  border-radius: 8px !important;
  border: 1px solid #5850b4a4 !important;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  width: 120px;
  color: #5850b4 !important;
}

.modalMsg {
  text-align: center;
}

.msg {
  padding: 80px;
}

@media only screen and (max-width: 1440px) {
  .connect {
    position: relative;
    left: -30px;
    border-radius: 5px !important;
    border: 1px solid #5850b46c !important;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100px;
    color: #5850b4 !important;
  }
}

@media only screen and (max-width: 1366px) {
  .connect {
    position: relative;
    top: 5px;
    left: -40px;
    border-radius: 5px !important;
    border: 1px solid #5850b46c !important;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100px;
    color: #5850b4 !important;
  }
}

@media only screen and (max-width: 1280px) {
  .connect {
    position: relative;
    left: 0;
    border-radius: 5px !important;
    border: 1px solid #5850b46c !important;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100px;
    color: #5850b4 !important;
  }
}

@media only screen and (max-width: 1144px) {
  .connect {
    position: relative;
    left: 0;
    border-radius: 5px !important;
    border: 1px solid #5850b46c !important;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100px;
    color: #5850b4 !important;
  }
}

@media only screen and (max-width: 1024px) {
  .connect {
    position: relative;
    top: 10px;
    left: -30px;
    border-radius: 5px !important;
    border: 1px solid #5850b46c !important;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 50px;
    padding-right: 12px;
    width: 60px;
    color: #5850b4 !important;
  }
}

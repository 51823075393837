.build {
  float: left;
  border: 1px solid #5850b46c !important;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  width: 82px;
  height: 70px;
  color: #5850b4 !important;
  margin: 2px;
  cursor: pointer;
  user-select: none;
}

.buildSelected {
  float: left;
  border: 1px solid #5850b4 !important;
  background-color: #5850b444;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  width: 82px;
  color: #5850b4 !important;
  margin: 2px;
  cursor: pointer;
  user-select: none;
}

.disabled {
  float: left;
  border: 1px solid #333333 !important;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  width: 82px;
  color: #5850b4 !important;
  margin: 2px;
  cursor: pointer;
  user-select: none;
  filter: grayscale(100%) brightness(70%);
}

.build:hover {
  border: 1px solid #5850b4 !important;
}

.build img {
  width: 62px;
}

.buildSelected img {
  width: 62px;
  filter: brightness(1.4);
}

.disabled img {
  width: 62px;
  filter: brightness(1);
}

.buildShow {
  float: left;
  width: 220px;
  height: 220px;
  padding: 20px;
}

.buildShow img {
  width: 160px;
  height: 160px;
}

.content {
  display: flex;
  width: 100%;
  height: 450px;
  border: 1px solid #5850b46c !important;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 2px;
  padding: 10px;
}

.contentDescription {
  position: relative;
  bottom: 25px;
  padding: 10px;
  width: 100%;
}

.description {
  font-size: 11px;
  font-weight: 400;
  text-align: justify;
  color: #f1f1f1;
  padding: 20px;
  margin-bottom: 10px;
  vertical-align: top;
}

.buildDescription {
  background-color: #5850b41c;
  padding: 10px;
  margin-top: 8px;
  color: #5850b4;
  margin-bottom: 8px;
}

.buildTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.underconstruction {
  position: relative;
  top: -165px;
  left: -70px;
  width: 250px;
}

.underconstructionSpecial {
  position: relative;
  top: -205px;
  left: -70px;
  width: 260px;
}

.underconstructionShield {
  position: relative;
  top: -220px;
  left: -70px;
  width: 260px;
}

.timer {
  z-index: 20;
  position: relative;
  font-weight: 600;
  color: #242424;
  top: -229px;
  left: 18px;
  animation: 1.5s show;
}

.timerSpecial {
  z-index: 20;
  position: relative;
  font-weight: 600;
  color: #242424;
  top: -271px;
  left: 22px;
  animation: 1.5s show;
}

.timerShield {
  z-index: 20;
  position: relative;
  font-weight: 600;
  color: #242424;
  top: -286px;
  left: 22px;
  animation: 1.5s show;
}

.timerEnd {
  opacity: 0;
}

.research {
  margin-left: 8px;
  color: #5850b4;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.control {
  z-index: 1;
  //left: 100px;
}

.number {
  font-size: 50px;
  position: relative;
  top: 10px;
}

.stage {
  text-align: right;
}

.desc {
  background-color: #0000004b;
  padding: 6px;
  width: 300px;
  text-align: center;
  border-radius: 5px;
  margin: 4px;
}

.tag {
  font-size: 11px;
  margin: 4px;
  padding: 2px;
  background-color: #5850b4a6;
  border-radius: 5px;
}

.clock {
  position: relative;
  left: -10px;
  font-size: 20px;
}

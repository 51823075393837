body {
  background-color: #000915;
}

.scena {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #000915;
  user-select: none;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAXAQMAAADJMwYXAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAA////pdmf3QAAAAJ0Uk5TAP9bkSK1AAAAU0lEQVR4nGNgAAF+MPEASLD/ARJs9kCCmR3IZ2b4AyLkGxgYG5gfMDC2Mx4AEo8bGBiO/weqOWYHJI6DNCcjCKAkgyGIMAYR5iAxORAhAyIkGBgAC4QQkZQqv1QAAAAASUVORK5CYII=)
      5 5,
    auto;
}

.info {
  position: absolute;
  top: 14px;
  left: 0px;
  font-size: 11px;
  width: 300px;
  color: #aaa;
  user-select: none;
}

.none {
  display: none;
}

.default {
  display: block;
}

.disabled {
  pointer-events: none !important;
  cursor: default !important;
  user-select: none !important;
  filter: opacity(0.1);
}

.regionLogo {
  width: 620px;
  position: fixed;
  z-index: 2;
  top: 140px;
  left: 160px;
  opacity: 0.3;
}

.content {
  width: 450px;
  vertical-align: top;
  border: 1px solid #5850b46c !important;
  background-color: #5850b41c;
  margin: 2px;
  padding: 20px;
  color: #5850b4;
}

.rarity {
  position: absolute;
  bottom: 188px;
  left: 0;
  width: 130px;
  padding: 2px;
  color: black;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  transform: rotate(-45deg) translate(-28%, -35%);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.rarity.common {
  background-color: #5850b4;
}

.rarity.uncommon {
  background-color: #3e8ede;
}

.rarity.rare {
  background-color: #79aa22;
}

.contentInative {
  width: 500px;
  vertical-align: top;
  border: 1px solid #cccccc6c !important;
  background-color: #cccccc1c;
  margin: 2px;
  padding: 20px;
  color: #5850b4;
}

.footer {
  position: relative;
  text-align: right;
  margin-top: 10px;
  padding-bottom: 14px;
  padding-top: 14px;
}

.footer button {
  margin-left: 12px;
}

.nftEmpty {
  width: 210px;
  height: 210px;
  border: #5850b44d 1px solid;
  text-align: center;
  margin-right: 40px;
  vertical-align: center;
  font-size: 44px;
  padding-top: 80px;
  font-weight: 600;
  color: #5850b47e;
}

.msg {
  text-align: center;
  padding: 20px;
}

.imgNFT {
  display: inline-block;
  width: 70px;
  height: 70px;
  margin-right: 10px;
  margin-bottom: -20px;
  cursor: pointer;
  border: 2px solid transparent;
}

.imgNFT.common {
  position: relative;
  bottom: 50px;
  border-color: #5850b4;
}

.imgNFT.uncommon {
  position: relative;
  bottom: 50px;
  border-color: #3e8ede;
}

.imgNFT.rare {
  position: relative;
  bottom: 50px;
  border-color: #79aa22;
}

.imgNFTSelected {
  float: left;
  width: 70px;
  margin-right: 20px;
  margin-bottom: 20px;
  border: #5850b4 1px solid;
  cursor: pointer;
}

.nftSelected {
  position: relative;
  top: 5px;
  width: 210px;
  height: 210px;
  border: #5850b44d 1px solid;
  text-align: center;
  margin-right: 50px;
  padding-top: 20px;
}

.nftSelected img {
  width: 160px;
  height: 160px;
}

.effect {
  font-size: 11px;
  color: #5850b4;
  width: 210px;
  padding: 12px;
  margin-top: 4px;
  background-color: #5850b418;
}

.pagination {
  position: relative;
  right: -120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  white-space: nowrap;
}

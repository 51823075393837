.navLeft {
  z-index: 10;
  position: relative;
  top: 34px;
  left: 326px;
  width: 30px;
  height: 80px;
  cursor: pointer;
}

.navRight {
  z-index: 10;
  position: relative;
  top: -188px;
  left: 476px;
  width: 30px;
  height: 80px;
  cursor: pointer;
}

.navLeftInactive {
  z-index: 10;
  position: relative;
  top: 34px;
  left: 326px;
  width: 30px;
  height: 80px;
  filter: brightness(70%);
  cursor: pointer;
}

.navRightInactive {
  z-index: 10;
  position: relative;
  top: -188px;
  left: 476px;
  width: 30px;
  height: 80px;
  filter: brightness(70%);
  cursor: pointer;
}

.navLeft:hover,
.navRight:hover {
  filter: brightness(130%);
}

.navLeftIco {
  z-index: 9;
  position: relative;
  top: 24px;
  left: 0;
  width: 10px;
  height: 30px;
  background-color: #5850b4;
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  vertical-align: center;
}

.navRightIco {
  z-index: 10;
  position: relative;
  top: 44px;
  left: 12px;
  width: 10px;
  height: 30px;
  background-color: #5850b4;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
}

.selector {
  z-index: 9;
  position: relative;
  top: -40px;
  left: 338px;
  text-align: center;
}

.selector img {
  width: 70px;
  user-select: none;
  cursor: pointer;
}

.planetName {
  text-align: center;
  font-size: 12px;
  color: #5850b4;
  font-weight: 600;
  position: relative;
}

.clock {
  font-size: 14px;
  color: #5850b4;
  font-weight: 600;
  position: relative;
  top: 24px;
}

.selectorDescription {
  position: relative;
  font-size: 11px;
  width: 150px;
  top: 10px;
}

.onFire {
  position: absolute;
  left: 39px;
  top: -6px;
}

@media only screen and (max-width: 1440px) {
  .navLeftIco {
    top: 20px;
    left: -15px;
  }

  .navRightIco {
    top: 16px;
    left: -15px;
  }

  .selector {
    top: -48px;
    left: 270px;
  }
}

@media only screen and (max-width: 1366px) {
  .navLeftIco {
    top: 15px;
    left: -40px;
  }

  .navRightIco {
    top: 13px;
    left: -50px;
  }

  .selector {
    top: -50px;
    left: 245px;
    text-align: center;
  }

  .selector img {
    width: 60px;
    height: 70px;
    user-select: none;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1280px) {
  .navLeftIco {
    top: 14px;
    left: -10px;
  }

  .navRightIco {
    top: 13px;
    left: -20px;
  }

  .selector {
    top: -55px;
    left: 270px;
  }
}

@media only screen and (max-width: 1114px) {
  .navLeftIco {
    position: relative;
    top: 10px;
    left: 90px;
    width: 10px;
    height: 20px;
    background-color: #5850b4;
    clip-path: polygon(100% 0, 0 50%, 100% 100%);
    vertical-align: center;
  }

  .navRightIco {
    z-index: 10;
    position: relative;
    top: 30px;
    left: 60px;
    width: 10px;
    height: 20px;
    background-color: #5850b4;
    clip-path: polygon(0 0, 100% 50%, 0 100%);
  }

  .selector {
    z-index: 10;
    position: relative;
    top: -50px;
    left: 248px;
    text-align: center;
  }
}

.content {
  margin: 10px;
  width: 610px;
  height: 436px;
  overflow-y: auto;
  overflow-x: hidden;
}

.messages {
  height: 80px;
}

.desc {
  font-size: 12px;
}

.noCombats {
  font-size: 12px;
  color: #ffffff;
  opacity: 0.7;
  padding: 20px;
}

.address {
  text-align: center;
  color: #5850b4;
  font-size: 10px;
}

.status {
  text-align: center;
  color: #5850b4;
  font-size: 14px;
}

.id {
  color: #5850b4;
  border: 1px solid #5850b4;
  border-radius: 2px;
  padding: 4px;
  width: 80px;
  text-align: center;
  margin: 4px;
  font-size: 10px;
}

.stage {
  text-align: center;
  color: #5850b4;
  font-size: 20px;
}

.timer {
  background-color: #00000041;
  font-size: 16px;
  color: #5850b4;
}

.clock {
  color: #5850b4;
}

.combat {
  text-align: center;
  background-color: #4a60771c;
  color: whitesmoke;
  border: 1px solid #5850b4;
  padding: 5px;
  margin: 5px;
  width: 600px;
  border-radius: 4px;
  cursor: pointer;
}

.combatNoRun {
  text-align: center;
  background-color: #4a60771c;
  color: whitesmoke;
  border: 1px solid #cccc;
  padding: 5px;
  margin: 5px;
  width: 600px;
  border-radius: 4px;
  cursor: pointer;
  filter: grayscale(100%);
}

.page {
  font-size: 12px;
  color: #f1f1f1;
  padding: 20px;
}

.planet {
  margin: 4px;
  user-select: none;
}

.title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.description {
  width: 700px;
  background-color: #5850b480;
  padding: 10px;
  margin-top: 8px;
  color: #f5a23c;
  margin-bottom: 8px;
}

.player {
  color: #5850b4;
  font-weight: 600;
  font-size: 15px;
}

.msgSended {
  color: red !important;
}
.newMsg {
  margin-left: 10%;
  width: 160%;
  // height: 80% !important;
  color: white;
}

.card {
  position: relative;
  float: left;
  margin: 6px;
  border: 1px solid #5850b48f;
  padding: 4px;
}

.price {
  font-size: 10px;
  padding: 2px;
  background-color: #5850b4d5;
  text-align: right;
}

.buy {
  padding: 10px;
  text-align: center;
}

.title {
  font-size: 10px;
  padding: 2px;
  background-color: #5850b4d5;
  //color: orangered;
}

.cardsBox {
  height: 400px;
  overflow: scroll;
}

.message {
  color: aquamarine;
  padding-left: 12px;
}

.msgConnect {
  padding: 100px;
  color: aquamarine;
  text-align: center;
}

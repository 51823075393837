.details {
  display: flex;
  color: #f1f1f1;
}
.AliancDetailLogo {
  width: 30%;
  padding: 1px;
  margin-right: 2px;
}
.alianceDetailDescription {
  width: 70%;
  padding: 1px;
  margin-left: 2px;
  overflow-x: auto;
}
.spaceTop {
  margin-top: 5px;
}
.titleDetail {
  color: #f1f1f1;
  font-weight: bold;
}

.btnAlianceDelete {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  color: #fff;
  padding: 10px 20px;
  border: solid 1px rgb(233, 83, 83);
  border-radius: 5px;
  cursor: pointer;
}

.labelAliance {
  margin-top: 2.5%;
  margin-bottom: -5%;
}
.btnAlianceDelete:hover {
  background-color: #5850b4;
  font-weight: bold;
  // scale: 1.1;
}

//edit
.editAliance {
  position: absolute;
  color: #f1f1f1;
  top: 0;
  right: 0;
  padding: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.editAliance:hover {
  scale: 1.2;
  color: #5850b4;
}

.message {
  position: absolute;
  color: #f1f1f1;
  top: 3px;
  left: 0;
  padding: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.message:hover {
  scale: 1.1;
  color: #5850b4;
}

.divider {
  margin-top: 1%;
  border-top: #5850b4 solid 1px;
  margin-bottom: 2%;
}

//  chat
.senders tr td {
  border-bottom: #5850b4 solid 1px;
  padding: 5px;
  cursor: pointer;
}

tr:hover {
  background-color: #5850b4;
}

.title {
  font-size: 1.1em;
  text-align: center;
  padding-bottom: 5px;
}

.conversatioBox {
  padding-top: 5px;
  height: 88%;
  display: flex;
  flex-direction: column;
  overflow: scroll !important;
}

.sendersBox {
  overflow: scroll !important;
  height: 88%;
}

.msgSended {
  text-align: left;
  padding: 5px;
  font-style: italic;
  flex-direction: column;
}

.msgReceived {
  text-align: right;

  padding: 5px;
}

.send {
  flex: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 8%;
}
.sendBlock {
  pointer-events: none;
}

.inputContainer input {
  flex: 1;
  border: none;
  background-color: transparent;
  padding: 0px;
  margin-left: -15px;
  font-size: 14px;
  height: 15px;
}

.inputContainer button {
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: #666;
  margin-left: 5px;
}

.inputContainer button:hover {
  color: #333;
  cursor: pointer;
}

.icon {
  color: #fff;
  transform: rotate(-45deg);
  animation: rotate 2s normal;
}

.nIcon {
  color: #fff;
  transform: rotate(45deg);
  animation: rotate 2s normal;
}

.container {
  display: flex;
  flex-direction: row;
  height: 600px;
}

.leftDiv {
  flex-basis: 30%;
  flex-grow: 0;
  padding: 10px;
  border-right: 1px solid #5850b4;
}

.rightDiv {
  display: inline;

  flex-basis: 70%;
  flex-grow: 0;
  padding: 10px;
  padding: 10px;
}

tr.dragged {
  background-color: lightblue;
  font-weight: bold;
}

.hours {
  color: #5850b4;
  font-size: 0.6em;
  font-weight: bold;
  margin-top: -2px;
  font-style: normal;
}

.info {
  color: #5850b4;
  font-size: 0.7em;
  font-weight: bold;
  margin-top: 5px;
  margin-right: -2px;
}

.checkicon {
  color: green;
  transform: scale(0.8);
}

.moreIcon {
  cursor: pointer;
  color: #ccc;
  // background-color: red;
  display: flex;
  align-items: center;
  padding-top: 1px;
  padding-bottom: 1px;
  // border: 1px solid #5850B4;
  border-radius: 4px;
  // background-color: #fff;
  cursor: pointer;
}

.moreIcon:hover {
  color: #5850b4;
}

.dropdown {
  margin-top: 3%;
  position: relative;
  display: inline-block;
}

.dropdownBtn:focus {
  outline: none;
}

.dropdownMenu {
  position: absolute;
  // margin-left: 0px !important;
  top: 85%;
  width: 140px;
  left: 0;
  z-index: 1;
  list-style: none;
  padding: 0px 2px 0px 0px;
  margin: 4px 0 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #24204b;
  color: #ccc;
}

.dropdownMenu li {
  padding: 8px;
  cursor: pointer;
}

.dropdownMenu li:hover {
  background-color: #5850b4;
  color: #ccc;
}

.myResource {
  font-size: 14px;
  padding: 8px 10px;
  border: solid 1px #5850b4;
  color: #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.myResourceQtd {
  font-weight: bold;
}

.error {
  position: relative;
  top: 0px;
  margin-top: 30px;
}

.sucess {
  position: relative;
  left: 220px;
  margin-top: 30px;
}

.button {
  position: relative;
  left: 310px;
  margin-top: 0px;
}

.label {
  margin-top: 2.5%;
  margin-bottom: -5%;
}

// radio button backbar
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: #5850b4;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px #5850b4;
    }

    &:checked + .slider:before {
      transform: translateX(26px);
    }
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #5850b4;
    border-radius: 26px;
    transition: background-color 0.2s;
    cursor: pointer;

    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      border-radius: 50%;
      transition: transform 0.2s;
    }
  }
}

.switchType {
  // background-color: red;
  position: absolute;
  // margin-top: -5%;
  z-index: 1;
  padding: 2px;
  right: 50px;
}

.container {
  padding: 20px;
  float: none;
}

.unit {
  float: left;
  border: 1px solid #5850b46c !important;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  width: 82px;
  height: 90px;
  color: #5850b4 !important;
  margin: 2px;
  cursor: pointer;
  user-select: none;
}

.unitBlock {
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #5850b46c !important;
}

.unitOnConstruct {
  float: left;
  border: 1px solid #5850b46c !important;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  width: 82px;
  height: 90px;
  color: #5850b480 !important;
  background-color: #5850b417;
  margin: 2px;
  cursor: pointer;
  user-select: none;
}

.unitOnConstruct img {
  width: 62px;
  filter: brightness(70%) grayscale(100%);
}

.unitImg {
  width: 82px;
}

.description {
  font-size: 12px;
  color: #d1d1d1;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #00000035;
  text-align: center;
}

.quantity {
  position: relative;
  top: 4px;
  background-color: #5850b4;
  color: #d1d1d1;
  border-radius: 5px;
  text-align: center;
  width: 80px;
  font-size: 12px;
  cursor: pointer;
}

.quantityOnConstruct {
  position: relative;
  top: 10px;
  font-size: 11px;
  background-color: #5850b4;
  color: #d1d1d1;
  border-radius: 5px;
  text-align: center;
  width: 60px;
  cursor: pointer;
}

.timer div {
  position: relative;
  top: -30px;
  color: #fff;
  font-weight: 600;
  background-color: #5850b4;
  border-radius: 5px;
  padding: 2px;
  width: 60px;
  font-size: 11px;
  text-align: center;
}

.msg {
  color: #5850b4;
}

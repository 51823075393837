/*search*/
.inputAliance {
  font-size: 14px;
  padding: 8px 10px;
  border: solid 1px #5850b4;
  color: #ccc;
  border-radius: 4px;
  // cursor: pointer;
}
.searchContainer {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}

.searchInput {
  padding-right: 30px;
}

.searchIcon {
  color: #f1f1f1;
  position: absolute;
  top: 60%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Efeito de esconder a lupa quando o campo estiver preenchido */
.searchContainer:focus-within .searchIcon {
  display: none;
}

.notFound {
  color: #f1f1f1;
  margin-top: 10px;
  margin-left: 2px;
  font-size: 14px;
}

.msgErro {
  text-align: center;
}

.btnContainer {
  margin-top: 20px;
}

.btnErro {
  position: relative;
  margin-top: -100px;
}

.frota {
  height: 180px !important;
  overflow: scroll !important;
  // background-color: red;
}
//  accordion
.accordion {
  border: 1px solid #5850b4;
  border-radius: 5px;
  background-color: red !important;
}

.accordionItem {
  border-bottom: 1px solid #5850b4;
}

.accordionHeader {
  background-color: transparent;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  color: #eee;
  font-weight: bold;
  width: 100%;
}

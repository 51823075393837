.box {
  display: flex;
  align-items: center;
  background-color: #5850b4;
  color: #fff;
  font-weight: bold;
  font-size: 9px;
  position: relative;
  width: 30px;
  padding-left: 4px;
  padding-top: 2px;
  text-align: center;
}

.senders tr td {
  // border-bottom: #5850B4 solid 1px;
  padding: 5px;
  cursor: pointer;
}

tr:hover {
  background-color: #5850b4;
}

.title {
  font-size: 1.1em;
  text-align: center;
  padding-bottom: 5px;
}

.conversatioBox {
  padding-top: 5px;
  height: 88%;
  display: flex;
  flex-direction: column;
  overflow: scroll !important;
}

.sendersBox {
  overflow: scroll !important;
  height: 100%;
}

.divider {
  border-top: #5850b4 solid 1px;
}

.msgSended {
  text-align: left;
  padding: 5px;
  font-style: italic;
  flex-direction: column;
}

.msgReceived {
  text-align: right;

  padding: 5px;
}

.send {
  flex: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 8%;
}

.sendBlock {
  pointer-events: none;
}

.inputContainer input {
  flex: 1;
  border: none;
  background-color: transparent;
  padding: 0px;
  margin-left: -15px;
  font-size: 14px;
  height: 15px;
}

.inputContainer button {
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: #666;
  margin-left: 5px;
}

.inputContainer button:hover {
  color: #333;
  cursor: pointer;
}

.icon {
  color: #fff;
  transform: rotate(-45deg);
  animation: rotate 2s normal;
}

.nIcon {
  color: #fff;
  transform: rotate(45deg);
  animation: rotate 2s normal;
}

.container {
  display: flex;
  flex-direction: row;
  height: 300px;
  width: 100%;
}

.leftDiv {
  flex-basis: 100%;
  flex-grow: 0;
  padding: 5px;
  // border-right: 1px solid #5850B4;
}

.rightDiv {
  display: inline;

  flex-basis: 70%;
  flex-grow: 0;
  padding: 10px;
  padding: 10px;
}

tr.dragged {
  background-color: lightblue;
  font-weight: bold;
}

.hours {
  color: #5850b4;
  font-size: 0.6em;
  font-weight: bold;
  margin-top: -2px;
  font-style: normal;
}

.iconDel {
  color: red;
  font-size: 0.1em;
  padding: 0;
}

.info {
  color: #5850b4;
  font-size: 0.6em;
  font-weight: bold;
  margin-top: 5px;
  margin-right: -2px;
}
.msgDeleted {
  margin-left: 30%;
  width: 50%;
  height: auto;
  background-color: #666;
  border-radius: 5px;
  font-style: italic;
  text-align: center !important;
}

.checkicon {
  color: green;
  transform: scale(0.8);
}

.chatWithAliance {
  position: absolute;
  color: #f1f1f1;
  top: 0;
  right: 0;
  padding: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.chatWithAliance:hover {
  scale: 1.2;
  color: #5850b4;
}

//chat com outra aliança
.tableAliance tr td {
  font-size: 12px;
  padding: 1px;
  cursor: pointer;
}

.tableAliance th {
  // background-color: #5850B4;
  padding: 2px;
  height: 25px;
  align-items: center;
}

.return {
  position: absolute;
  color: #f1f1f1;
  top: 3px;
  left: 0;
  padding: 5px;
  background-color: transparent;
  // color: red;
  border: none;
  cursor: pointer;
  z-index: 2;
}

.return:hover {
  scale: 1.1;
  color: #5850b4;
}

.nameAliance {
  position: absolute;
  color: #f1f1f1;
  top: 12px;
  left: 10%;
  cursor: pointer;
  font-style: italic;
}

.interacao {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #f1f1f1;
}

.balance {
  padding: 4px;
  text-align: right;
  font-size: 20px;
  color: aquamarine;
}

.balance img {
  position: relative;
  left: -4px;
  top: -2px;
}

.mainBalance {
  cursor: pointer;
  width: 130px;
}

.mainBalance:hover {
  color: aquamarine;
  background-color: rgba(71, 61, 139, 0.514);
}

.mainBalance img {
  position: relative;
  left: -4px;
}

.nftTitle {
  font-size: 12px;
  font-weight: 600;
  width: 180px;
  border-bottom: #5850b481 1px solid;
}

.nftSlot {
  float: left;
  width: 40px;
  height: 39px;
  margin: 2px;
  border: #5850b44d 1px solid;
  position: relative;
  top: 4px;
  text-align: center;
  vertical-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #5850b47e;
  cursor: pointer;
}

.nftSlot:hover {
  color: #5850b4;
  filter: brightness(130%);
}

@media only screen and (max-width: 1114px) {
  .nftTitle {
    font-size: 10px;
    font-weight: 600;
    width: 150px;
    border-bottom: #5850b481 1px solid;
  }

  .nftSlot {
    float: left;
    width: 20px;
    height: 20px;
    margin: 7px;
    border: #5850b44d 1px solid;
    position: relative;
    top: 4px;
    text-align: center;
    vertical-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #5850b47e;
    cursor: pointer;
  }
}

@import "mixins";

.slot {
  position: relative;
  width: 110px;
  height: 64px;
  z-index: 10;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAXCAYAAADz/ZRUAAAAAXNSR0IArs4c6QAAAq5JREFUSEuVls2LjmEUxn9nIxsbG6WU9K4kiqJmYlYiysbs+RcsJJl8lCws/A8sRwovDWslCzORhamXBRuL0ZTyMbI43J/P/fU873h3733fzznXdZ1zrvsWBFCm/5JzIqB932Sb7iO75DOI2hW33sh6GFgAFgUeKnwvz0QcBYqURxe+RNmdaiU3a1+AHcBv4LkBAjyiBCKCYxK4kAg5IKnfqpO7jTsCFwrMBsizBMiPoEjNssW7BmOEa5X8CPAq5ZEnYsMCERZRHgMRyGDzFMn6kpsYn4FdNlgTYViUDdAlMaURxqoGSNzzWGK7ZVRbNQ/gbyNcNLKkoVyYrmiu5vH3C1jyioyBn0NK1Mw7loeA19NnMDmRozRAngL3gScOSC6hlBKJ7d1ATj6C7qkA2BibMwh/6hOwNyoRhBMRNHGMIuQt4HLfnLfNpgK1BswAH1pxepUVOKDwJh7Is40RjqNsrQNE/b+CHgPeuzOV7MUQRS+MbTQBRuY721zd9ydBXoCeAs6CnAbdloRfR5hFWW2XzUHpnya3dUOEq7YyOfC7AueTTt8CnDBABOYUzgDv8sRpAEm8PcCoLgzZB+qDZO1sPH878GdwnPxkd2k79xua8zTmRGCUTEEwi3ngwSbUy0QznMN90BjUqjmuAddTNL73TOL52gFDg/RoEkfNo+iTzp8bgUyClyVNbyQ30ttrd9CFY4W7uiay9xY9BH0L7Lcpck89B9zLxzEg8Yk6pvERYpasw0VXK+AXTK4AN4vRWAOZAa0MJOhQRM8Ezhsu2GbCLBl7c8OZmy781oFZkNX6Ui4L0P5fyD71MbcMHET4JnBU1c9xEvs/bN++7arHRLNxHN9LIAugc8DKVHvqs7ZgbI66eNucynw3yE7Ql6H2ot3MZne9iepfuc1W/rf4F465AyuE7wMuAAAAAElFTkSuQmCC)
      5 5,
    auto;
  clip-path: polygon(
    30% 0,
    70% 0,
    100% 30%,
    100% 70%,
    70% 100%,
    30% 100%,
    0 70%,
    0 30%
  );
  background-color: #000000;
  opacity: 0;
  user-select: none;
}

.slot:hover {
  animation: vanishIn 0.5s;
  opacity: 0.15;
  user-select: none;
}

.slotDisable {
  position: relative;
  width: 110px;
  height: 64px;
  z-index: 10;
  clip-path: polygon(
    30% 0,
    70% 0,
    100% 30%,
    100% 70%,
    70% 100%,
    30% 100%,
    0 70%,
    0 30%
  );
  background-color: #000000;
  opacity: 0;
  user-select: none;
}

.mainSlot {
  position: relative;
  width: 110px;
  height: 64px;
  z-index: 10;
  clip-path: polygon(
    30% 0,
    70% 0,
    100% 30%,
    100% 70%,
    70% 100%,
    30% 100%,
    0 70%,
    0 30%
  );
  background-color: #000000;
  opacity: 0.2;
  user-select: none;
}

.mainSlot:hover {
  animation: vanishIn 0.5s;
  opacity: 0.15;
  user-select: none;
}

.construct {
  //  z-index: 11;
  position: relative;
  top: -48px;
  left: -22px;
  width: 110px;
  height: 70px;
  transform: none;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAXCAYAAADz/ZRUAAAAAXNSR0IArs4c6QAAAq5JREFUSEuVls2LjmEUxn9nIxsbG6WU9K4kiqJmYlYiysbs+RcsJJl8lCws/A8sRwovDWslCzORhamXBRuL0ZTyMbI43J/P/fU873h3733fzznXdZ1zrvsWBFCm/5JzIqB932Sb7iO75DOI2hW33sh6GFgAFgUeKnwvz0QcBYqURxe+RNmdaiU3a1+AHcBv4LkBAjyiBCKCYxK4kAg5IKnfqpO7jTsCFwrMBsizBMiPoEjNssW7BmOEa5X8CPAq5ZEnYsMCERZRHgMRyGDzFMn6kpsYn4FdNlgTYViUDdAlMaURxqoGSNzzWGK7ZVRbNQ/gbyNcNLKkoVyYrmiu5vH3C1jyioyBn0NK1Mw7loeA19NnMDmRozRAngL3gScOSC6hlBKJ7d1ATj6C7qkA2BibMwh/6hOwNyoRhBMRNHGMIuQt4HLfnLfNpgK1BswAH1pxepUVOKDwJh7Is40RjqNsrQNE/b+CHgPeuzOV7MUQRS+MbTQBRuY721zd9ydBXoCeAs6CnAbdloRfR5hFWW2XzUHpnya3dUOEq7YyOfC7AueTTt8CnDBABOYUzgDv8sRpAEm8PcCoLgzZB+qDZO1sPH878GdwnPxkd2k79xua8zTmRGCUTEEwi3ngwSbUy0QznMN90BjUqjmuAddTNL73TOL52gFDg/RoEkfNo+iTzp8bgUyClyVNbyQ30ttrd9CFY4W7uiay9xY9BH0L7Lcpck89B9zLxzEg8Yk6pvERYpasw0VXK+AXTK4AN4vRWAOZAa0MJOhQRM8Ezhsu2GbCLBl7c8OZmy781oFZkNX6Ui4L0P5fyD71MbcMHET4JnBU1c9xEvs/bN++7arHRLNxHN9LIAugc8DKVHvqs7ZgbI66eNucynw3yE7Ql6H2ot3MZne9iepfuc1W/rf4F465AyuE7wMuAAAAAElFTkSuQmCC)
      5 5,
    auto;
  user-select: none;
}

.colonization {
  z-index: 11;
  width: 161px;
  position: relative;
  top: -58px;
  left: -29px;
  user-select: none;
}

.shield {
  z-index: 11;
  width: 120px;
  position: relative;
  top: -94px;
  left: -6px;
  user-select: none;
}

.default {
  z-index: 11;
  width: 110px;
  position: relative;
  top: -44px;
  user-select: none;
}

.imageBuild img:hover {
  filter: brightness(110%) contrast(105%) saturate(130%)
    drop-shadow(0px 0px 8px #fff);
  user-select: none;
  animation: 0.5s show;
}

.imageBuild {
  z-index: 20;
  height: 68px;
}

.disabled {
  pointer-events: none !important;
  cursor: default !important;
  user-select: none !important;
  filter: opacity(0.4);
  user-select: none;
}

.imageBuildOnConstruct {
  position: relative;
  z-index: 20;
  opacity: 0;
}

.upText {
  background-color: #5850b41c;
  border-radius: 3px;
  padding: 5px;
  font-weight: bold;
  font-size: 13px;
  color: #aaaaaa;
}

.upgrade {
  width: 400px;
}

@keyframes vanishIn {
  0% {
    opacity: 0;
    filter: blur(70px);
  }
  100% {
    opacity: 0.15;
    filter: blur(0px);
  }
}

@keyframes show {
  0% {
    filter: brightness(100%) contrast(100%) saturate(100%)
      drop-shadow(0px 0px 0px #fff);
  }
  100% {
    filter: brightness(110%) contrast(105%) saturate(130%)
      drop-shadow(0px 0px 8px #fff);
  }
}

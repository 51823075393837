@import "mixins";

.level {
  font-size: 32px;
  padding: 8px;
  margin: 4px;
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 25%, 75% 0%);
  background-color: #5850b4;
}

.label {
  position: relative;
  left: 50px;
}

.priceLabel {
  position: relative;
  top: 20px;
  left: 40px;
  font-size: 12px;
}

.buildContent {
  width: 438px;
  vertical-align: top;
  border-radius: 2px !important;
  border: 1px solid #5850b46c !important;
  background-color: #5850b41c;
  margin: 2px;
  margin-top: -50px;
  height: 570px;
  padding: 20px;
  color: #5850b4;
}
//

.footer {
  position: relative;
  text-align: right;
  margin-top: -10px;
  padding-bottom: 14px;
  padding-top: 14px;
}

.lineDetails {
  text-align: left;
  border: 1px solid #5850b46c;
  border-radius: 2px;
  padding: 4px;
  margin: 4px;
  font-size: 15px;
  color: #f1f1f1;
}

.tag {
  border: 1px solid #5850b4;
  border-radius: 2px;
  padding: 3px;
  width: 80px;
  text-align: center;
  margin: 3px;
  font-size: 14px;
  color: #f1f1f1;
}

.level {
  font-size: 32px;
  padding: 8px;
  margin: 4px;
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 25%, 75% 0%);
  background-color: #5850b4;
}

.tip {
  padding-top: 12px;
  padding-bottom: 12px;
}

.tipMetal {
  position: relative;
  left: 200px;
  bottom: 58px;
}

.stats {
  font-size: 16px;
}

.buildContent2 {
  width: 100%;
  vertical-align: top;
  border-radius: 4px !important;
  border: 1px solid #5850b46c !important;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 2px;
  padding: 10px;
  color: #5850b4;
}

.buildContent table tr {
  color: #f1f1f1;
  border: 1px solid #5850b409;
}

.buildImage {
  //float: left;
  width: 240px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.tag {
  border: 1px solid #5850b4;
  border-radius: 2px;
  padding: 4px;
  width: 80px;
  text-align: center;
  margin: 4px;
  font-size: 14px;
  color: #f1f1f1;
}

.tag2 {
  background-color: #444444;
  border-radius: 10px;
  padding: 4px;
  text-align: center;
  margin: 4px;
  font-size: 14px;
  color: #f1f1f1;
}

.tag3 {
  border: 1px solid #5850b4;
  border-radius: 2px;
  padding: 4px;
  margin: 0;
  height: 26px;
  text-align: center;
  font-size: 14px;
  color: #f1f1f1;
}

.muttedTag {
  background-color: rgba(54, 54, 56, 0.904);
  border: 1px solid rgba(54, 54, 56, 0.904);
  border-radius: 5px;
  padding: 2px;
  width: 80px;
  text-align: center;
}

.inputControl input[type="number"] {
  //background-color: #4a6077;
  outline: none;
  color: #f1f1f1;
  font-size: 28px !important;
  font-family: "Chakra Petch" !important;
  border-radius: 0px;
  border: none !important;
  height: 50px;
  width: 140px !important;
  height: 30px !important;
}

.inputControl input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
  padding: 20px;
  font-size: 80px;
}

.input {
  padding: 8px;
  font-size: 16px;
}

.box {
  border: 1px solid #4a60771c;
  padding: 20px;
}

.hBox {
  float: left;
  border: 1px solid #4a60771c;
  padding: 20px;
  height: 320px;
  width: 150px;
}

.decriptionSide {
  padding: 20px;
  border: 1px solid #4a60771c;
}

.vTitle td {
  width: 130px;
  text-align: center;
  padding: 4px;
  border: 1px solid #4a60771c;
}

.vControl td {
  height: 300px;
  text-align: center;
  padding: 14px;
  border: 1px solid #4a60771c;
}

.vNumber td {
  text-align: center;
  padding: 4px;
  font-size: 16px;
  border: 1px solid #4a60771c;
}

.snackbar {
  z-index: 1900 !important;
  position: absolute !important;
  //left: 20px !important;
  text-align: right;
  top: 0px !important;
}

.simpleAlert {
  position: relative;
  bottom: 630px;
  right: 130px;
}

.alert {
  position: relative;
  left: 22px;
  height: 40px;
  background-color: #5850b409 !important;
  margin: 0px !important;
  color: #5850b4 !important;
  font-family: "Chakra Petch" !important;
  padding: 10px;
}

.generalAlert {
  position: absolute;
  right: 135px;
  top: 170px;
  height: 30px;
  background-color: #5850b434 !important;
  margin: 1px !important;
  color: #5850b4 !important;
  font-family: "Chakra Petch" !important;
  padding: 7px;
}

.tipMetal {
  position: relative;
  left: 200px;
  bottom: 75px;
}

.generalAlertSlider {
  position: absolute;
  left: 280px;
  top: 520px;
  height: 30px;
  background-color: #5850b4 !important;
  margin: 1px !important;
  color: white !important;
  font-size: 12px;
  font-weight: bold;
  font-family: "Chakra Petch" !important;
  padding: 7px;
}

.robots {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5850b4 !important;
  margin: 1px !important;
  color: white !important;
  font-size: 15px;
  font-weight: bold;
  font-family: "Chakra Petch" !important;
  padding: 7px;
}

.alert div {
  position: relative;
  top: -6px;
}

.alert img {
  color: #5850b4;
}

.MuiAlert-icon {
  color: #5850b4;
}

.imageMold {
  border: 2px solid #4a6077a2;
  margin-bottom: 8px;
}

.imageItem {
  float: left;
  width: 70px;
  height: 70px;

  --g: 1px;
  /* the gap */
  --b: 1px;
  /* border thickness*/
  --c: #5850b4;
  /* the color */

  padding: calc(var(--g) + var(--b));
  --_c: #00000000 0 25%, var(--c) 0 50%;
  --_g1: repeating-linear-gradient(90deg, var(--_c)) repeat-x;
  --_g2: repeating-linear-gradient(180deg, var(--_c)) repeat-y;
  background: var(--_g1) var(--_p, 25%) 0, var(--_g2) 0 var(--_p, 125%),
    var(--_g1) var(--_p, 125%) 100%, var(--_g2) 100% var(--_p, 25%);
  background-size: 200% var(--b), var(--b) 200%;
  cursor: pointer;
  filter: grayscale(50%);
  filter: opacity(75%);
  transition: 0.25s;
}

.divItem {
  float: left;
  margin: 7px;
}

.divItem image {
  border: 1px solid #aaaaaa;
  cursor: pointer;
}

.divItem :hover {
  --_p: 75%;
  filter: grayscale(0%);
  filter: opacity(100%);
}

.statusLabel {
  font-size: 12px;
  position: relative;
  left: 4px;
}

.line {
  width: 180px;
  text-align: right;
  border: 1px solid #5850b46c;
  border-radius: 2px;
  padding: 4px;
  margin: 4px;
  font-size: 14px;
  color: #f1f1f1;
}

.imageDescription {
  width: 140px;
  height: 140px;
}

.militaryDescription {
  width: 325px;
  padding-right: 20px;
  padding-left: 20px;
}

.upText {
  background-color: #5850b41c;
  border-radius: 3px;
  padding: 8px;
  font-size: 12px;
  color: #aaaaaa;
}

.upResources {
  font-size: 12px;
  color: #aaaaaa;
}

.upgrade {
  width: 240px;
}

.resourceCount {
  width: 800px;
}

.input {
  padding: 10px;
}

.upIcon {
  width: 118px;
}

.danger {
  width: 110px;
  margin-left: 4px;
  border-color: rgb(151, 10, 10);
  color: rgb(151, 10, 10);
}

.danger:hover {
  background-color: rgb(151, 10, 10) !important;
  color: #fff !important;
  border-color: rgb(151, 10, 10) !important;
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.actions {
  width: 650px;
  text-align: right;
  margin-top: 100px;
}

.reqs {
  padding: 10px;
}

.fundationDesc {
  color: smokeWhite;
  font-size: 11px;
  width: 500px;
}

.value {
  color: #5850b4;
  font-size: 16px;
  position: relative;
  top: 8px;
}

.btnColonizer {
  margin-top: 15%;
  position: relative;
  flex-direction: column;
  align-items: center;
  // left: -400px;
  display: flex;
  height: 150px;
  margin-bottom: 15%;
}

.options {
  margin-top: 70px;
  position: absolute;
  top: 2px;
  margin-left: 45px;
  width: 600px;
  background-color: #5850b410;
  text-align: right;
  background-color: transparent;
}

.myResource {
  margin-bottom: 10px;
  font-size: 14px;
  padding: 8px 10px;
  border: solid 1px #5850b4;
  color: #ccc;
  border-radius: 4px;
  cursor: pointer;
  flex: 80%;
  width: auto;
}

.imgResource {
  width: 40px;
  margin-right: 5px;
}

.label {
  margin-left: 10%;
  flex: 100%;
  margin-bottom: 5px;
}

.btnColonizer h1 {
  margin-top: -2%;
}

.info {
  margin-top: 10px;
  color: white;
}
.infoNotAllowed {
  margin-top: 10px;
  color: red;
}

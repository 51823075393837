.unit {
  float: left;
  border: 1px solid #5850b46c !important;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  width: 112px;
  color: #5850b4 !important;
  margin: 2px;
  cursor: pointer;
  user-select: none;
}

.unitSelected {
  float: left;
  border: 1px solid #5850b4 !important;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  width: 112px;
  color: #5850b4 !important;
  background-color: #5850b444;
  margin: 2px;
  cursor: pointer;
  user-select: none;
}

.unit:hover {
  border: 1px solid #5850b4 !important;
}

.unit img {
  width: 88px;
  filter: brightness(0.95);
}

.unitSelected img {
  width: 88px;
  filter: brightness(1.4);
}

.unitShow {
  float: left;
  width: 240px;
  height: 240px;
  padding: 20px;
}

.unitShow img {
  width: 160px;
  height: 160px;
}

.buildContent {
  width: 690px;
  vertical-align: top;
  border-radius: 2px !important;
  border: 1px solid #5850b46c !important;
  background-color: #5850b410;
  margin: 2px;
  padding: 20px;
  color: #5850b4;
}

.footer {
  position: relative;
  text-align: right;
  margin-top: 10px;
  padding-bottom: 14px;
  padding-top: 14px;
}

.statusLabel {
  font-size: 11px;
  font-weight: 600;
  position: relative;
  left: 1px;
}

.unitName {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  width: 650px;
}

.unitDescription {
  background-color: #5850b41c;
  padding: 10px;
  width: 650px;
  height: 76px;
  margin-top: 20px;
  color: #5850b4;
  overflow: scroll;
  font-size: 11px;
}

.unitResources {
  border: 1px solid #5850b427 !important;
  padding: 4px;
  width: 420px;
}

.clock {
  border: #5850b427 1px solid;
  font-size: 12px;
  text-align: center;
}

input {
  color: #f1f1f1;
  font-size: 16px !important;
  border-radius: 0px;
  padding: 6px;
}

.tag {
  border: 1px solid #5850b4;
  border-radius: 2px;
  padding: 4px;
  width: 80px;
  text-align: center;
  margin: 4px;
  font-size: 14px;
  color: #f1f1f1;
}

.tag2 {
  background-color: #444444;
  border-radius: 10px;
  padding: 4px;
  text-align: center;
  margin: 4px;
  font-size: 14px;
  color: #f1f1f1;
}

.lineDetails {
  text-align: left;
  border: 1px solid #5850b46c;
  border-radius: 2px;
  padding: 4px;
  margin: 4px;
  font-size: 15px;
  color: #f1f1f1;
}
.specialTitle {
  font-size: 10px;
  font-weight: bold;
  background-color: #5850b4;
  padding: 2px;
  width: 70px;
  text-align: center;
}

.level {
  font-size: 32px;
  padding: 8px;
  margin: 4px;
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 25%, 75% 0%);
  background-color: #5850b4;
}

.buildContent {
  width: 408px;
  vertical-align: top;
  border-radius: 2px !important;
  border: 1px solid #5850b46c !important;
  background-color: #5850b41c;
  margin: 2px;
  padding: 20px;
  color: #5850b4;
}

.qtdResource {
  color: aquamarine;
  font-size: 14px;
}

.qtdResourceCrystal {
  color: cyan;
  font-size: 14px;
}

.miningPanel {
  padding: 6px;
  text-align: right;
  font-size: 32px;
  color: aquamarine;
  font-family: "Roboto Mono", monospace;
}

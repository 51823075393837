.car {
  position: relative;
}

.car img {
  width: 90px;
}

.move1 {
  -webkit-animation: move1-animation 24s infinite linear;
  animation: move1-animation 24s infinite linear;
}

.move2 {
  -webkit-animation: move2-animation 24s infinite linear;
  animation: move2-animation 24s infinite linear;
}

.move4 {
  -webkit-animation: move4-animation 24s infinite linear;
  animation: move4-animation 24s infinite linear;
}

.move5 {
  -webkit-animation: move5-animation 24s infinite linear;
  animation: move5-animation 24s infinite linear;
}

@-webkit-keyframes move1-animation {
  0%,
  10% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  40%,
  50% {
    -webkit-transform: translateX(400px) translateY(245px);
    transform: translateX(400px) translateY(245px);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}

@keyframes move1-animation {
  0%,
  10% {
    transform: translateX(0) translateY(0);
  }
  40%,
  50% {
    transform: translateX(400px) translateY(245px);
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}
@-webkit-keyframes move2-animation {
  0%,
  10% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  40%,
  50% {
    -webkit-transform: translateX(500px) translateY(300px);
    transform: translateX(500px) translateY(300px);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}

@keyframes move2-animation {
  0%,
  10% {
    transform: translateX(0) translateY(0);
  }
  40%,
  50% {
    transform: translateX(500px) translateY(300px);
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}

/* Keyframes com prefixo para o move4 */
@-webkit-keyframes move4-animation {
  0%,
  10% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  40%,
  50% {
    -webkit-transform: translateX(420px) translateY(250px);
    transform: translateX(420px) translateY(250px);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}

@keyframes move4-animation {
  0%,
  10% {
    transform: translateX(0) translateY(0);
  }
  40%,
  50% {
    transform: translateX(420px) translateY(250px);
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}

/* Keyframes com prefixo para o move5 */
@-webkit-keyframes move5-animation {
  0%,
  10% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
  40%,
  50% {
    -webkit-transform: translateX(260px) translateY(150px);
    transform: translateX(260px) translateY(150px);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}

@keyframes move5-animation {
  0%,
  10% {
    transform: translateX(0) translateY(0);
  }
  40%,
  50% {
    transform: translateX(260px) translateY(150px);
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}

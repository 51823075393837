.custom {
    background-color: rgba(0, 0, 0, 0);    
}


.shepherd-button-custom{
    background-color: rgba(219, 121, 1, 1) !important;
}

.custom-step-content {
    display: flex;
    align-items: center;
    color:white;
  }
  
  .text-content {
    flex-grow: 1; 
  }
  
  .image-content {
    flex-shrink: 0; 
    padding-left: 20px; 
    position:relative;
    top:11px;
    right:10px;
  }
  
  .custom-step-content img {
    width: 100px; 
    height: auto;
  }
  
  .image-content img.flipped {
    position: relative;
    top:40px;
    transform: scaleX(-1);
  }
  
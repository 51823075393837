//scores
.tbScores tr td {
  font-size: 12px;
  padding: 1px;
  height: 25px;
  cursor: pointer;
}

.tbScores {
  width: 110%;
}
.tbScores th {
  background-color: #5850b4;
  padding: 2px;
  height: 25px;
}

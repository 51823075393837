.progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.progress-rectangle {
  height: 100%;
  opacity: 0.4;
  float: left;
  margin-right: 2px;
}

.progress-rectangle:last-child {
  margin-right: 0;
}

/* small */
.progress-bar-small {
  height: 13px;
  width: 140px;
}

.progress-bar-small .progress-rectangle {
  width: calc((100% - 40px) / 14);
}

/* medium */
.progress-bar-medium {
  height: 30px;
  width: 50%;
}

.progress-bar-medium .progress-rectangle {
  width: calc((100% - 50px) / 20);
}

/* large */
.progress-bar-large {
  height: 40px;
  width: 80%;
}

/* custom build */
.progress-bar-custom-build {
  height: 15px;
  width: 380px;
}

.progress-bar-custom-build .progress-rectangle {
  width: calc((100% - 40px) / 14);
}

.progress-bar-large .progress-rectangle {
  width: calc((100% - 70px) / 20);
}

.action {
  display: flex;
  justify-content: center;
  color: #5850b4a4;
  width: 60px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 4px;
  padding: 6px;
  border: 1px solid #5850b48f;
  cursor: pointer;
}
.activeAction2 {
  display: flex;
  justify-content: center;
  background-color: #5850b456;
  color: #5850b4;
  border: 1px solid #5850b4;
  width: 60px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 4px;
  padding: 6px;
}

.action:hover {
  background-color: #5850b456;
  color: #5850b4;
  border: 1px solid #5850b4;
}

.actions {
  float: left;
  display: flex;
  margin-left: 16px;
}

.option {
  cursor: pointer;
  margin: 8px;
}

.option img {
  width: 70px;
  filter: grayscale(100%);
}

.option img:hover {
  width: 70px;
  filter: grayscale(0%);
  animation: show 0.5s ease;
}
.disabledAction {
  pointer-events: none;
  opacity: 0.5;
}

@keyframes show {
  0% {
    filter: grayscale(100%);
  }
  100% {
    filter: grayscale(0%);
  }
}

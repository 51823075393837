.box {
  display: flex;
  align-items: center;
  background-color: #bebbe2;
  color: #0a0915;
  font-weight: bold;
  font-size: 9px;
  position: relative;
  width: 30px;
  padding-left: 4px;
  padding-top: 2px;
}

.boxDestak {
  display: flex;
  align-items: center;
  background-color: #bebbe2a1;
  font-weight: bold;
  font-size: 9px;
  position: relative;
  width: 30px;
  padding-left: 4px;
  padding-top: 2px;
}

.ranking tr td {
  font-size: 10px;
  padding: 5px;
  cursor: pointer;
}

.ranking {
  width: 162px;
}

.loading {
  position: relative;
  left: 35px;
}

.boxPosition {
  font-style: italic;
  font-weight: 600;
  color: #5850b4;
}
.destak {
  // font-size: 16px;
  font-weight: bold;
  color: aqua;
}

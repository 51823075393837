.disabled {
  pointer-events: none !important;
  cursor: default !important;
  user-select: none !important;
  filter: opacity(0.1);
}

.planet {
  cursor: pointer;
  width: 70px;
  height: 70px;
  position: relative;
  top: -35px;
  left: -35px;
  opacity: 0;
}

.label {
  width: 120px;
  position: relative;
  left: -60px;
  user-select: none;
  animation: show 2s;
  z-index: 1;
  text-align: center;
  font-family: "Chakra Petch";
}

.unexplored {
  color: #f1f1f1;
}

.yellowTritOn {
  color: yellow;
  border: 1px solid yellow;
  border-radius: 4px;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

.myPlanetLabel {
  font-size: 11px;
  color: #2bdcbc;
  font-weight: 600;
}

.otherPlanetLabel {
  font-size: 11px;
  color: #f1f1f1;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.arrow {
  position: absolute;
  left: 430px;
}

.flagIcon {
  position: relative;
  left: -5px;
  width: 20px;
}

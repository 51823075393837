@import "mixins";

.overlay {
  @include flex;
  background: rgba(0, 0, 0, 0.582);

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 1050;
}

.modal {
  display: grid;
  gap: 32px;

  background: #111111e3;
  box-shadow: var(--box-shadow);
  outline: 0;
  position: relative;
  clip-path: polygon(
    0% 0%,
    0% 98%,
    2% 100%,
    98% 100%,
    100% 98%,
    100% 2%,
    98% 0%
  );

  vertical-align: top;

  @include desktop {
    padding: 40px;
    width: 900px;
  }

  @include mobile {
    padding: 48px 20px;
    width: calc(100% - 40px);
  }
}

.close {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 20px;
  height: 40px;
  width: 40px;
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 30%, 70% 0%);
  background-color: var(--button-primary-bg);
  margin: 16px;
}

.close span {
  position: relative;
  top: 4px;
  left: -2px;
}

.header {
  height: 40px;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.466);
  position: relative;
  top: -34px;
  left: 60px;
  padding-left: 40px;
  padding-top: 10px;
}

.icon {
  font-size: 56px;
  line-height: 1;
}

.title {
  font-size: 14px;
}

.confirm {
  font-size: inherit;
}

.main {
  overflow: hidden; // Sub components declare their own max-height and overflow-y
  position: relative;
  top: -20px;
  left: 60px;
  width: 730px;
  vertical-align: top;
}

.footer {
  margin-top: 8px;
  background-color: pink;
}

.element01 {
  position: absolute;
  width: 730px;
  height: 1px;
  background-color: #4a535f;
  top: 64px;
  left: 100px;
}

.element02 {
  position: absolute;
  width: 20px;
  height: 60px;
  border-top: 3px solid #738296;
  border-bottom: 3px solid #738296;
  top: 6px;
  left: 70px;
}

.element03 {
  position: absolute;
  width: 6px;
  height: 28px;
  top: 12px;
  left: 10px;
  background-color: var(--button-primary-bg);
}

.element04 {
  position: absolute;
  width: 2px;
  height: 240px;
  top: 60px;
  left: 12px;
  background-color: #738296;
}

.element05 {
  position: absolute;
  width: 60px;
  height: 36px;
  clip-path: polygon(
    0% 0%,
    0% 84%,
    10% 100%,
    100% 100%,
    100% 98%,
    10% 98%,
    2% 84%,
    2% 0%
  );
  bottom: 12px;
  left: 12px;
  background-color: #738296;
}

.element06 {
  position: absolute;
  width: 4px;
  height: 3px;
  bottom: 58px;
  left: 12px;
  background-color: #738296;
}

.element07 {
  position: absolute;
  width: 20px;
  height: 3px;
  bottom: 12px;
  left: 80px;
  background-color: var(--button-primary-bg);
}

.element08 {
  position: absolute;
  bottom: 14px;
  left: 48px;
  opacity: 0.5;
}

.element09 {
  position: absolute;
  color: var(--button-primary-bg);
  bottom: 14px;
  left: 84px;
  opacity: 0.5;
}

.element10 {
  position: absolute;
  color: var(--button-primary-bg);
  bottom: 14px;
  left: 84px;
  opacity: 0.5;
}

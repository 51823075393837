.button {
  position: relative;
  height: 34px !important;
  width: 120px !important;
  background-color: #24204b;
  border: 0;
  margin: 8px;
  cursor: pointer;
  color: #f1f1f1;
  text-align: center;

  &:hover {
    background-color: #5850b4;
    color: #f1f1f1;
  }

  clip-path: polygon(0 10px, 0 34px, 140px 34px, 150px 24px, 150px 0, 10px 0);
}

.active {
  position: relative;
  height: 34px !important;
  width: 120px !important;
  border: 0;
  margin: 8px;
  cursor: pointer;
  text-align: center;
  background-color: #5850b4;
  color: #f1f1f1;
  clip-path: polygon(0 10px, 0 34px, 140px 34px, 150px 24px, 150px 0, 10px 0);
}

.title {
  font-size: 1.1em;
  text-align: center;
  padding-bottom: 5px;
}

.divider {
  border-top: #5850b4 solid 1px;
}

.container {
  display: flex;
  flex-direction: row;
  height: auto;
}

.bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: -25px;
  height: 45px;
  border-bottom: #5850b4 solid 1px;
}

.blockItens {
  pointer-events: none;
  opacity: 0.5;
}

.leftDiv {
  flex-basis: 50%;
  flex-grow: 0;
  padding: 10px;
  border-right: 1px solid #5850b4;
}

.rightDiv {
  display: inline;

  flex-basis: 50%;
  flex-grow: 0;
  padding: 10px;
  padding: 10px;
}

.box {
  display: flex;
  align-items: center;
  background-color: #5850b4;
  color: #fff;
  font-weight: bold;
  font-size: 9px;
  position: relative;
  width: 30px;
  padding-left: 4px;
  padding-top: 2px;
  text-align: center;
}

.conversatioBox {
  padding-top: 5px;
  height: 88%;
  display: flex;
  flex-direction: column;
  overflow: scroll !important;
}

.marketBox {
  overflow: scroll !important;
  height: 88%;
}

.lastSale {
  font-size: 14px;
  // background-color: yellow;
  margin-top: -15%;
  padding: 5px;
  margin-bottom: 8%;
  border: solid 1px #5850b4;
  color: #ccc;
  border-radius: 4px;
}

.lastSalePrice {
  font-weight: bold;
}

/*More icones e back bar button*/

.moreIcon {
  cursor: pointer;
  color: #ccc;
  // background-color: red;
  display: flex;
  align-items: center;
  padding-top: 1px;
  padding-bottom: 1px;
  border: 1px solid #5850b4;
  border-radius: 4px;
  // background-color: #fff;
  cursor: pointer;
}

.moreIcon:hover {
  color: #5850b4;
}

.dropdown {
  margin-top: 3%;
  position: relative;
  display: inline-block;
}

.dropdownBtn:focus {
  outline: none;
}

.dropdownMenu {
  position: absolute;
  margin-left: -100px !important;
  top: 100%;
  width: 140px;
  left: 0;
  z-index: 1;
  list-style: none;
  padding: 0px 2px 0px 0px;
  margin: 4px 0 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #24204b;
  color: #ccc;
}

.dropdownMenu li {
  padding: 8px;
  cursor: pointer;
}

.dropdownMenu li:hover {
  background-color: #5850b4;
  color: #ccc;
}

.backbar {
  margin-top: 10px;
  width: 39vh;
  padding: 5px;
  color: #ccc;
}

.backicon {
  padding-left: 3px;
  padding-right: 3px;
  margin-top: 0;
  cursor: pointer;
}

.backtitle {
  color: #fff;
  font-weight: bold;
  margin-left: 50px;
}

.itensBackBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
// Menu lateral, tritium e energia
.menuLateral {
  font-size: 14px;
  padding: 5px;
  margin-top: -5%;
  color: #ccc;
  border-radius: 4px;
  position: absolute;
  left: 31%;
  z-index: 0;
}

.menuLateral ul {
  list-style: none;
  padding: 0;
}

.menuLateral li {
  padding: 10px;
  writing-mode: vertical-rl;
  text-orientation: upright;
  position: relative;
  background-color: #24204b;
  border: 0;
  margin: 8px;
  cursor: pointer;
  color: #f1f1f1;
  text-align: center;

  &:hover {
    background-color: #5850b4;
    color: #f1f1f1;
  }

  // clip-path: polygon(10px 0, 34px 0, 34px 140px, 24px 150px, 0 150px, 0 10px); vertical
  clip-path: polygon(10px 0, 30px 0, 30px 140px, 24px 150px, 0 150px, 0 10px);

  // clip-path: polygon(0 10px, 0 34px, 140px 34px, 150px 24px, 150px 0, 10px 0); horizontal
}

// radio button backbar
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  padding: 0px !important;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: #5850b4;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px #5850b4;
    }

    &:checked + .slider:before {
      transform: translateX(26px);
    }
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #5850b4;
    border-radius: 26px;
    transition: background-color 0.2s;
    cursor: pointer;

    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      border-radius: 50%;
      transition: transform 0.2s;
    }
  }
}
.areaSwitch {
  margin-right: 12px;
}
.switchLabel {
  color: #ccc;
  font-weight: bold;
  padding-top: 10px;
}

.options {
  margin-top: 40px;
  padding: 40px;
  width: 650px;
  height: 400px;
  background-color: #5850b410;
  color: #f5a23c;
  margin-bottom: 8px;
}

.content {
  width: 438px;
  vertical-align: top;
  border-radius: 2px !important;
  border: 1px solid #5850b46c !important;
  background-color: #5850b41c;
  margin: 2px;
  padding: 20px;
  color: #5850b4;
}

.legend {
  font-size: 11px;
  color: #5850b4;
}

.tag {
  border: 1px solid #5850b4;
  border-radius: 2px;
  padding: 3px;
  width: 80px;
  text-align: center;
  margin: 3px;
  font-size: 14px;
  color: #f1f1f1;
}

.tagPlanet {
  padding: 3px;
  width: 140px;
  text-align: center;
  margin: 3px;
  font-size: 14px;
  color: #f1f1f1;
}

.footer {
  position: relative;
  left: 438px;
  margin-top: 10px;
  padding-bottom: 14px;
  padding-top: 14px;
}

.tritiumBalance {
  margin-left: 40px;
  border: 1px solid #5850b4;
  border-radius: 2px;
  padding: 3px;
  text-align: right;
  font-size: 14px;
  color: #f1f1f1;
  width: 260px;
}

.labelPwTrit {
  font-size: 11px;
  text-align: right;
  width: 300px;
}

.actions {
  width: 650px;
  text-align: right;
}

.actions button {
  margin: 10px;
}

.lineDetails {
  margin-top: 1.3em;
}

.content {
  margin: 10px;
  width: 600px;
}

.messages {
  height: 80px;
}

.address {
  font-size: 10px;
  color: #5850b4;
}

.tableMissions {
  th,
  th {
    font-size: 11px;
    color: #ffffff;
    font-weight: bold;
    padding: 10px;
    position: sticky;
    top: 0;
    text-transform: uppercase;
    background-color: #0000004f;
  }

  td {
    padding: 10px;
    font-size: 11px;
    color: #ffffff;
    opacity: 0.7;
    border-top: 1px solid var(--card-border);
    height: 5px;
  }
}

.tableMissions tbody tr:hover {
  color: #ffffff;
  opacity: 1;
  background-color: #0000004f;
}

.tableMissions thead tr:hover {
  color: #ffffff;
  opacity: 1;
  background-color: #0000004f;
}

.load {
  color: #ffffff;
  margin-top: 5px;
}

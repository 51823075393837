@import "mixins";

.control {
  @include flex;
  user-select: none;
}

.shape {
  z-index: 5;
  position: fixed;
  width: 2060px;
  top: 0px;
}

.web3control {
  z-index: 10;
  color: #5850b4;
  position: relative;
  left: 30px;
  top: -90px;
}

.toggle {
  position: relative;
  top: -35px;
  left: 155px;
}

.connectWallet {
  z-index: 10;
  color: #5850b4;
  position: relative;
  left: -100px;
  top: -53px;
}

.galaxyName {
  z-index: 10;
  position: relative;
  left: 130px;
  top: -110px;
  border: #5850b4 1px solid;
  padding: 3px;
  width: 140px;
  color: #5850b4;
}

//new population
.tritiumBlock {
  z-index: 10;
  border-right: #5850b4 1px solid;
  height: 100px;
  position: relative;
  top: -60px;
  left: 76px;
  padding: 4px;
}

.energyBlock {
  z-index: 10;
  height: 100px;
  position: relative;
  top: -60px;
  left: 76px;
  padding: 4px;
}

.specialTitle {
  font-size: 10px;
  font-weight: bold;
  background-color: #5850b4;
  padding: 2px;
  width: 70px;
  text-align: center;
}

.ranking {
  z-index: 10;
  font-size: 12px;
  font-weight: bold;
  padding: 4px;
  position: relative;
  top: -56px;
  left: 315px;
  color: #fff;
  max-height: 130px;
  // overflow-y: scroll;
  overflow: scroll;
}

.rankingMore {
  z-index: 11;
  position: relative;
  top: 0px;
  left: 46px;
  font-size: 9px;
  font-weight: 600;
  color: #0a0915;
  background-color: #5850b4;
  text-align: center;
  padding: 1px;
  width: 80px;
  height: 12px;
  clip-path: polygon(0 24%, 0 100%, 96% 100%, 100% 76%, 100% 0, 4% 0);
  cursor: pointer;
}

.specialTitleRanking {
  z-index: 10;
  font-size: 10px;
  font-weight: bold;
  top: -114px;
  left: 435px;
  position: relative;
  background-color: #5850b4;
  padding: 2px;
  width: 70px;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.specialCounter {
  font-size: 12px;
  border: #5850b4 1px solid;
  text-align: center;
}

.perfil {
  z-index: 10;
  position: relative;
  top: -60px;
  left: -1070px;
  width: 160px;
  padding: 2px;
  text-align: center;
}

.resources {
  z-index: 10;
  position: relative;
  left: 444px;
  top: -56px;
}

.countResource {
  z-index: 10;
  position: relative;
  font-size: 12px;
  left: 2px;
}

.resourceBlock {
  margin-bottom: 8px;
}

/*.researchPoint {
  z-index: 10;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  top: -68px;
  left: 270px;
}*/

.points {
  position: relative;
  left: 110px;
  bottom: 17px;
}

.tritium {
  z-index: 10;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  top: -90px;
  left: 370px;
}

.nft {
  z-index: 10;
  width: 180px;
  position: relative;
  top: -30px;
  left: 105px;
}

.resourceIco img {
  position: absolute;
  left: -34px;
  width: 34px;
}

@media only screen and (max-width: 1630px) {
  .shape {
    z-index: 5;
    position: fixed;
    width: 1920px;
    top: 0px;
  }

  .web3control {
    z-index: 10;
    color: #5850b4;
    position: relative;
    left: 50px;
    top: -106px;
  }

  .connectWallet {
    z-index: 10;
    color: #5850b4;
    position: relative;
    left: -50px;
    top: -53px;
  }

  .galaxyName {
    z-index: 10;
    position: relative;
    left: 50px;
    top: -110px;
    border: #5850b4 1px solid;
    padding: 3px;
    width: 140px;
  }

  .tritiumBlock {
    z-index: 10;
    border-right: #5850b4 1px solid;
    height: 100px;
    position: relative;
    top: -60px;
    left: 0px;
    padding: 4px;
  }

  .energyBlock {
    z-index: 10;
    height: 100px;
    position: relative;
    top: -60px;
    left: 0px;
    padding: 4px;
  }

  .specialTitle {
    font-size: 10px;
    font-weight: bold;
    background-color: #5850b4;
    padding: 2px;
    width: 70px;
    text-align: center;
  }

  .ranking {
    z-index: 10;
    font-size: 12px;
    font-weight: bold;
    padding: 4px;
    position: relative;
    top: -56px;
    left: 250px;
    color: #fff;
    max-height: 130px;
    // overflow-y: scroll;
    overflow: scroll;
  }

  .rankingMore {
    z-index: 11;
    position: relative;
    top: 0px;
    left: 46px;
    font-size: 9px;
    font-weight: 600;
    color: #333;
    background-color: #5850b4;
    text-align: center;
    padding: 1px;
    width: 80px;
    height: 12px;
    clip-path: polygon(0 24%, 0 100%, 96% 100%, 100% 76%, 100% 0, 4% 0);
    cursor: pointer;
  }

  .specialTitleRanking {
    z-index: 10;
    font-size: 10px;
    font-weight: bold;
    top: -118px;
    left: 370px;
    position: relative;
    background-color: #5850b4;
    padding: 2px;
    width: 70px;
    text-align: center;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .specialCounter {
    font-size: 12px;
    border: #5850b4 1px solid;
    text-align: center;
  }

  .perfil {
    z-index: 10;
    position: relative;
    top: -60px;
    left: -1074px;
    width: 160px;
    padding: 2px;
    text-align: center;
  }

  .resources {
    z-index: 10;
    position: relative;
    left: 364px;
    top: -56px;
  }

  .countResource {
    z-index: 10;
    position: relative;
    font-size: 12px;
    left: 2px;
  }

  .resourceBlock {
    margin-bottom: 8px;
  }

  .researchPoint {
    z-index: 10;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    white-space: nowrap;
    top: -68px;
    left: 250px;
  }

  .points {
    position: relative;
    left: 110px;
    bottom: 17px;
  }

  .tritium {
    z-index: 10;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    white-space: nowrap;
    top: -100px;
    left: 312px;
  }

  .nft {
    z-index: 10;
    width: 180px;
    position: relative;
    top: -30px;
    left: 147px;
  }

  .resourceIco img {
    position: absolute;
    left: -34px;
    width: 34px;
  }
}

@media only screen and (max-width: 1440px) {
  .shape {
    width: 1920px;
  }

  .web3control {
    z-index: 10;
    color: #5850b4;
    position: relative;
    left: -30px;
    top: -70px;
  }

  .connectWallet {
    z-index: 10;
    color: #5850b4;
    position: relative;
    left: -50px;
    top: -60px;
  }

  .toggle {
    position: relative;
    top: -73px;
    left: 120px;
  }

  .galaxyName {
    z-index: 10;
    position: relative;
    left: 50px;
    top: -110px;
    border: #5850b4 1px solid;
    padding: 3px;
    width: 130px;
  }

  .tritiumBlock {
    z-index: 10;
    border-right: #5850b4 1px solid;
    height: 100px;
    position: relative;
    top: -60px;
    left: 35px;
    padding: 4px;
  }

  .energyBlock {
    z-index: 10;
    height: 100px;
    position: relative;
    top: -60px;
    left: 35px;
    padding: 4px;
  }

  .specialTitle {
    font-size: 10px;
    font-weight: bold;
    background-color: #5850b4;
    padding: 2px;
    width: 70px;
    text-align: center;
  }

  .ranking {
    z-index: 10;
    font-size: 12px;
    font-weight: bold;
    padding: 45px;
    position: relative;
    top: -83px;
    left: 230px;
    color: #fff;
    max-height: 160px;
    // overflow-y: scroll;
    overflow: scroll;
  }

  .rankingMore {
    z-index: 11;
    position: relative;
    top: -10px;
    left: 40px;
    font-size: 9px;
    font-weight: 600;
    color: #333;
    background-color: #5850b4;
    text-align: center;
    padding: 1px;
    width: 80px;
    height: 12px;
    clip-path: polygon(0 24%, 0 100%, 96% 100%, 100% 76%, 100% 0, 4% 0);
    cursor: pointer;
  }

  .specialTitleRanking {
    z-index: 10;
    font-size: 10px;
    font-weight: bold;
    top: -118px;
    left: 380px;
    position: relative;
    background-color: #5850b4;
    padding: 2px;
    width: 70px;
    text-align: center;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .specialCounter {
    font-size: 12px;
    border: #5850b4 1px solid;
    text-align: center;
  }

  .perfil {
    z-index: 10;
    position: relative;
    top: -60px;
    left: -1050px;
    width: 160px;
    padding: 2px;
    text-align: center;
  }

  .resources {
    z-index: 10;
    position: relative;
    left: 390px;
    top: -60px;
  }

  .countResource {
    z-index: 10;
    position: relative;
    font-size: 12px;
    left: 2px;
  }

  .resourceBlock {
    margin-bottom: 8px;
  }

  .researchPoint {
    z-index: 10;
    width: 300px;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    top: -70px;
    left: 263px;
  }

  .points {
    position: relative;
    left: 110px;
    bottom: 17px;
  }

  .tritium {
    z-index: 10;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    top: -100px;
    width: 150px;
    left: 280px;
  }

  .nft {
    z-index: 10;
    width: 180px;
    position: relative;
    top: -40px;
    left: 125px;
  }

  .resourceIco img {
    position: absolute;
    left: -34px;
    width: 34px;
  }
}

@media only screen and (max-width: 1366px) {
  .shape {
    width: 1850px;
  }

  .web3control {
    z-index: 10;
    color: #5850b4;
    position: relative;
    left: -20px;
    top: -70px;
  }

  .connectWallet {
    z-index: 10;
    color: #5850b4;
    position: relative;
    left: -10px;
    top: -68px;
  }

  .galaxyName {
    z-index: 10;
    position: relative;
    left: 80px;
    top: -110px;
    border: #5850b4 1px solid;
    padding: 3px;
    width: 130px;
  }

  .tritiumBlock {
    z-index: 10;
    border-right: #5850b4 1px solid;
    height: 100px;
    position: relative;
    top: -60px;
    left: 18px;
    padding: 4px;
  }

  .energyBlock {
    z-index: 10;
    height: 100px;
    position: relative;
    top: -60px;
    left: 18px;
    padding: 4px;
  }

  .specialTitle {
    font-size: 10px;
    font-weight: bold;
    background-color: #5850b4;
    padding: 2px;
    width: 70px;
    text-align: center;
  }

  .ranking {
    z-index: 10;
    font-size: 12px;
    font-weight: bold;
    padding: 60px;
    position: relative;
    top: -100px;
    left: 170px;
    color: #fff;
    max-height: 170px;
    // overflow-y: scroll;
    overflow: scroll;
  }

  .rankingMore {
    z-index: 11;
    position: relative;
    top: -10px;
    left: 20px;
    font-size: 9px;
    font-weight: 600;
    color: #333;
    background-color: #5850b4;
    text-align: center;
    padding: 1px;
    width: 80px;
    height: 12px;
    clip-path: polygon(0 24%, 0 100%, 96% 100%, 100% 76%, 100% 0, 4% 0);
    cursor: pointer;
  }

  .specialTitleRanking {
    z-index: 10;
    font-size: 10px;
    font-weight: bold;
    top: -118px;
    left: 330px;
    position: relative;
    background-color: #5850b4;
    padding: 2px;
    width: 70px;
    text-align: center;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .specialCounter {
    font-size: 12px;
    border: #5850b4 1px solid;
    text-align: center;
  }

  .perfil {
    z-index: 10;
    position: relative;
    top: -70px;
    left: -980px;
    width: 160px;
    padding: 2px;
    text-align: center;
  }

  .resources {
    z-index: 10;
    position: relative;
    left: 365px;
    top: -60px;
  }

  .countResource {
    z-index: 10;
    position: relative;
    font-size: 12px;
    left: 2px;
  }

  .resourceBlock {
    margin-bottom: 8px;
  }

  .researchPoint {
    z-index: 10;
    width: 310px;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    top: -70px;
    left: 250px;
  }

  .points {
    position: relative;
    left: 110px;
    bottom: 17px;
  }

  .tritium {
    z-index: 10;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    width: 185px;
    top: -100px;
    left: 250px;
  }

  .nft {
    z-index: 10;
    width: 180px;
    position: relative;
    top: -45px;
    left: 145px;
  }

  .resourceIco img {
    position: absolute;
    left: -34px;
    width: 34px;
  }
}

@media only screen and (max-width: 1280px) {
  .shape {
    width: 1920px;
  }

  .web3control {
    z-index: 10;
    color: #5850b4;
    position: relative;
    left: -50px;
    top: -90px;
  }

  .connectWallet {
    z-index: 10;
    color: #5850b4;
    position: relative;
    left: -80px;
    top: -78px;
  }

  .galaxyName {
    z-index: 10;
    position: relative;
    width: auto;
    left: 80px;
    top: -120px;
    border: #5850b4 1px solid;
    padding: 3px;
  }

  .tritiumBlock {
    z-index: 10;
    border-right: #5850b4 1px solid;
    height: 100px;
    position: relative;
    top: -65px;
    left: 30px;
    padding: 4px;
  }

  .energyBlock {
    z-index: 10;
    height: 100px;
    position: relative;
    top: -65px;
    left: 30px;
    padding: 4px;
  }

  .specialTitle {
    font-size: 10px;
    font-weight: bold;
    background-color: #5850b4;
    padding: 2px;
    width: 70px;
    text-align: center;
  }

  .ranking {
    z-index: 10;
    font-size: 12px;
    font-weight: bold;
    padding: 150px;
    position: relative;
    top: -120px;
    left: 120px;
    color: #fff;
    max-height: 130px;
    // overflow-y: scroll;
    overflow: scroll;
  }

  .rankingMore {
    z-index: 11;
    position: relative;
    top: 0px;
    left: 40px;
    font-size: 9px;
    font-weight: 600;
    color: #333;
    background-color: #5850b4;
    text-align: center;
    padding: 1px;
    width: 80px;
    height: 12px;
    clip-path: polygon(0 24%, 0 100%, 96% 100%, 100% 76%, 100% 0, 4% 0);
    cursor: pointer;
  }

  .specialTitleRanking {
    z-index: 10;
    font-size: 10px;
    font-weight: bold;
    top: -125px;
    left: 370px;
    position: relative;
    background-color: #5850b4;
    padding: 2px;
    width: 70px;
    text-align: center;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .specialCounter {
    font-size: 12px;
    border: #5850b4 1px solid;
    text-align: center;
  }

  .perfil {
    z-index: 10;
    position: relative;
    top: -70px;
    left: -1000px;
    width: 160px;
    padding: 2px;
    text-align: center;
  }

  .resources {
    z-index: 10;
    position: relative;
    left: 385px;
    top: -70px;
  }

  .countResource {
    z-index: 10;
    position: relative;
    font-size: 12px;
    left: 2px;
  }

  .resourceBlock {
    margin-bottom: 8px;
  }

  .researchPoint {
    z-index: 10;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    white-space: nowrap;
    top: -75px;
    left: 190px;
  }

  .points {
    position: relative;
    left: 110px;
    bottom: 17px;
  }

  .tritium {
    z-index: 10;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    white-space: nowrap;
    width: 185px;
    top: -110px;
    left: 160px;
  }

  .nft {
    z-index: 10;
    width: 180px;
    position: relative;
    top: -45px;
    left: 125px;
  }

  .resourceIco img {
    position: absolute;
    left: -34px;
    width: 34px;
  }
}

@media only screen and (max-width: 1180px) {
  .shape {
    width: 1680px;
  }

  .web3control {
    z-index: 10;
    color: #5850b4;
    position: relative;
    left: -60px;
    top: -80px;
  }

  .connectWallet {
    z-index: 10;
    color: #5850b4;
    position: relative;
    left: -100px;
    top: -80px;
  }

  .galaxyName {
    z-index: 10;
    position: relative;
    width: auto;
    left: 120px;
    top: -120px;
    border: #5850b4 1px solid;
    padding: 3px;
  }

  .tritiumBlock {
    z-index: 10;
    border-right: #5850b4 1px solid;
    height: 100px;
    position: relative;
    top: -72px;
    left: 30px;
    padding: 4px;
  }

  .energyBlock {
    z-index: 10;
    height: 100px;
    position: relative;
    top: -72px;
    left: 30px;
    padding: 4px;
  }

  .specialTitle {
    font-size: 10px;
    font-weight: bold;
    background-color: #5850b4;
    padding: 2px;
    width: 70px;
    text-align: center;
  }

  .ranking {
    z-index: 10;
    font-size: 12px;
    font-weight: bold;
    padding: 150px;
    position: relative;
    top: -100px;
    left: 80px;
    color: #fff;
    max-height: 130px;
    // overflow-y: scroll;
    overflow: scroll;
  }

  .rankingMore {
    z-index: 11;
    position: relative;
    top: 0px;
    left: 40px;
    font-size: 9px;
    font-weight: 600;
    color: #333;
    background-color: #5850b4;
    text-align: center;
    padding: 1px;
    width: 80px;
    height: 12px;
    clip-path: polygon(0 24%, 0 100%, 96% 100%, 100% 76%, 100% 0, 4% 0);
    cursor: pointer;
  }

  .specialTitleRanking {
    z-index: 10;
    font-size: 10px;
    font-weight: bold;
    top: -125px;
    left: 330px;
    position: relative;
    background-color: #5850b4;
    padding: 2px;
    width: 70px;
    text-align: center;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .specialCounter {
    font-size: 12px;
    border: #5850b4 1px solid;
    text-align: center;
  }

  .perfil {
    z-index: 10;
    position: relative;
    top: -80px;
    left: -915px;
    width: 160px;
    padding: 2px;
    text-align: center;
  }

  .resources {
    z-index: 10;
    position: relative;
    left: 372px;
    top: -70px;
  }

  .countResource {
    z-index: 10;
    position: relative;
    font-size: 12px;
    left: 2px;
  }

  .resourceBlock {
    margin-bottom: 8px;
  }

  .researchPoint {
    z-index: 10;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    white-space: nowrap;
    top: -75px;
    left: 190px;
  }

  .points {
    position: relative;
    left: 110px;
    bottom: 17px;
  }

  .tritium {
    z-index: 10;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    white-space: nowrap;
    width: 185px;
    top: -110px;
    left: 120px;
  }

  .nft {
    z-index: 10;
    width: 180px;
    position: relative;
    top: -45px;
    left: 90px;
  }

  .resourceIco img {
    position: absolute;
    left: -34px;
    width: 34px;
  }
}

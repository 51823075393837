.regionPanel {
  position: fixed;
  top: 220px;
  left: 20px;
  z-index: 1000;
}

.regionButton {
  margin: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid rgba(255, 166, 0, 0.39);
  cursor: pointer;
}

.regionButton:hover {
  margin: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid rgb(255, 166, 0);
  cursor: pointer;
}

.regionName {
  display: none;
}

.regionNameActive {
  display: block;
}

.regionPopover {
  padding: 12px;
  width: 160px;
  height: 140px;
  background-color: #00000079;
  color: #f1f1f1;
}

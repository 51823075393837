.text {
  position: absolute;
  background-color: #00000000;
  top: 220px;
  left: 0px;
  width: 400px;
  font-family: "Chakra Petch";
  font-size: 28px;
  color: #f1f1f1;
}

.cursor {
  font-family: "Chakra Petch";
  font-size: 24px;
  font-weight: bold;
  color: #f1f1f1;
  animation: cursor 0.4s infinite;
}

@keyframes cursor {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 1366px) {
  .text {
    position: absolute;
    background-color: #00000000;
    top: 320px;
    left: 0px;
    width: 400px;
    font-family: "Chakra Petch";
    font-size: 18px;
    color: #f1f1f1;
  }
}

@import "mixins";

.tabs {
  white-space: nowrap;
}

.card {
  background: var(--bg-muted);
  border: var(--border-width) solid var(--card-border);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 11px;
  font-weight: var(--bold);
  margin-bottom: 20px;
  overflow: hidden;

  @include mobile {
    display: flex;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }
}

.scifi {
  font-size: 11px;
  font-weight: var(--bold);
  margin-bottom: 20px;
  overflow: hidden;
  height: 50px;
  position: relative;
  top: -5px;

  @include mobile {
    display: flex;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }
}

.tab {
  @include inline-flex;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

.card .tab {
  height: 40px;
  border-right: var(--border-width) solid var(--card-border);
  color: var(--text-muted);

  @include mobile {
    &:last-child {
      border-right: 0;
    }
  }

  &:hover {
    color: var(--text);
  }

  &.active {
    background: var(--card-bg);
    color: var(--text);
  }

  &.disabled {
    color: var(--text-muted);
  }

  @include desktop {
    padding: 0 14px;
  }

  @include mobile {
    flex: 1;
    text-align: center;
  }
}

.scifi .tab {
  height: 46px;
  width: 70px;
  color: #95b3c4;
  border-bottom: 2px solid #95b3c4;
  margin-right: 6px;
  margin-bottom: 4px;
  margin-top: 0px;
  padding: 0;

  @include mobile {
    &:last-child {
      border-right: 0;
    }
  }

  &:hover {
    color: var(--text);
    border-bottom: 4px solid #5850b4;
  }

  &.active {
    color: #95b3c4;
    border-bottom: 4px solid #5850b4;
  }

  &.disabled {
    //color: var(--text-muted);
    color: #50525596;
    border-bottom: 2px solid #505255;
  }

  @include mobile {
    flex: 1;
    text-align: center;
  }
}

.line {
  --border-color: var(--text);
}

.reversed {
  --border-color: var(--card-bg);
}

.line .tab {
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 12px;
  font-weight: var(--bold);
  padding: 0 14px;
  padding-bottom: 5px;

  &.active {
    border-bottom: 2px solid var(--border-color);
    padding-bottom: 4px;
  }
}

.disabled {
  @include disabled;
}

.transparent {
  color: rgba(0, 0, 0, 0);
}

.resource {
  margin-top: 60px;
  padding: 40px;
  margin-left: 46px;
  width: 600px;
  height: 400px;
  background-color: #5850b410;
  color: #f5a23c;
  margin-bottom: 8px;
  // float: inline;
  // padding: 40px;
  // margin-left: 46px;
  // width: 600px;
  // height: 380px;
  // background-color: #5850B410;
  // color: #f5a23c;
  // margin-bottom: 8px;
}

.gridContainer {
  padding-top: 2px;
  padding-bottom: 5px;
  margin-top: 45px;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items por linha */
  gap: 16px; /* Espaçamento entre os itens (ajuste conforme necessário) */
}

.gridItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.strategySelected {
  color: #f5a23c;
  font-style: italic;
}

// .unitImg {
//   width: 62px;
// }

.actions {
  width: 650px;
  text-align: right;
}

.actions button {
  margin: 10px;
}

.options {
  margin-top: 70px;
  position: absolute;
  top: 2px;
  margin-left: 45px;
  width: 600px;
  background-color: #5850b410;
  text-align: right;
  background-color: transparent;
}

.myResource {
  margin-bottom: 10px;
  font-size: 14px;
  padding: 8px 10px;
  border: solid 1px #5850b4;
  color: #ccc;
  border-radius: 4px;
  cursor: pointer;
}

// teste
/* Adicione estilos para o container pai */
.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.label {
  margin-left: 10%;
  flex: 100%;
  margin-bottom: 5px;
}

.imgResouece {
  // flex: 10%;
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.myResource {
  flex: 80%;
  width: auto;
}

.lineDetails {
  text-align: left;
  border: 1px solid #5850b46c;
  border-radius: 2px;
  padding: 4px;
  margin: 4px;
  font-size: 15px;
  color: #f1f1f1;
}

.circularSliderContainer {
  position: relative;
  margin: 100px;
  left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Toggle {
  display: flex;
  justify-content: center;
}

.footer {
  position: relative;
  text-align: right;
  top: 100px;
  left: 30px;
  padding-bottom: 14px;
  padding-top: 14px;
  border-top: 1px solid #95b3c448;
}

.Account {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputDiv {
  margin-right: 20px;
  width: 300px;
}

@media only screen and (max-width: 1440px) {
  .settings {
    position: relative;
    left: -10px;
    bottom: 0px;
  }
}

@media only screen and (max-width: 1366px) {
  .settings {
    position: relative;
    left: -10px;
    bottom: 0px;
  }
}

.orderby {
  position: relative;
  margin-bottom: 10px;
}

.footer {
  position: fixed;
  font-size: 13px;
  bottom: 10px;
  right: 40px;
  color: #d1d1d1;
  z-index: 1;
}
.icon {
  color: #fff;
  transform: rotate(-45deg);
}
.inputContainer input {
  flex: 1;
  border: none;
  background-color: transparent;
  padding: 0px;
  margin-left: -15px;
  font-size: 14px;
  height: 15px;
}
.inputContainer button {
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: #666;
  margin-left: 5px;
}

.inputContainer button:hover {
  color: #333;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: row;
  height: 600px;
}

.send {
  margin-top: 3%;
}

.titleFrom {
  border-bottom: 1px solid #5850b4 !important;
  padding: 2px;
  width: 94%;
}

.msgInPlanetDetail {
  position: fixed;
  margin-top: 30%;
  margin-left: 10%;
}

.btnCancel {
  margin-top: 3%;
}

.notification {
  position: absolute;
  top: 0px;
  right: -6px;
  width: 16px;
  height: 16px;
  background-color: #5850b4;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  z-index: 1;
}

@media only screen and (max-width: 1440px) {
  .message {
    position: relative;
    left: 48px;
    bottom: 37px;
  }

  .help {
    position: relative;
    bottom: 38px;
    left: 55px;
  }
}

@media only screen and (max-width: 1366px) {
  .message {
    position: relative;
    left: 45px;
    bottom: 37px;
  }

  .notification {
    right: -75px;
  }

  .help {
    position: relative;
    bottom: 38px;
    left: 55px;
  }
}

@media only screen and (max-width: 1280px) {
  .notification {
    right: -95px;
  }
}

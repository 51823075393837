@use "sass:math";
@import "mixins";

/* SCI FI BUTTON */
.siDivScifi {
  /* == BUTTON SETTINGS == */
  --color: white;
  --background-color: #000000;
  --border-color: #5850b4;
  --border-width: 1px;
  --border-cut: 8px;
  --button-shape: polygon(
    var(--border-cut) 0,
    100% 0,
    100% calc(100% - var(--border-cut)),
    calc(100% - var(--border-cut)) 100%,
    0 100%,
    0 var(--border-cut)
  );
}

.siDivScifi {
  color: var(--color) !important;
  border: 0 !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  justify-self: center !important;
  align-self: center !important;
  vertical-align: middle !important;
  appearance: none !important;
  box-sizing: border-box !important;
  text-decoration: none !important;
  position: relative !important;
  word-spacing: 3px !important;
  letter-spacing: 0.4px !important;
  line-height: initial !important;
  min-height: 30px !important;
  min-width: 70px !important;
  white-space: nowrap !important;
  z-index: 1 !important;
  outline: 0 !important;
  padding: 10px 30px !important;
  background-color: transparent !important;
}

.siDivScifi:after,
.siDivScifi:before {
  content: "";
  position: absolute;
  inset: var(--border-width);
  background: var(--background-color);
  z-index: -1;
  clip-path: var(--button-shape);
  box-shadow: inset 0 0 25px -10px var(--border-color);
}

.siDivScifi:before {
  inset: 0;
  background: var(--border-color);
}

.helper1:before,
.helper1:after {
  content: "";
  position: absolute;
  height: 35%;
  width: 3px;
  background: var(--border-color);
  left: -1px;
  top: 50%;
  transform: translateY(-50%);
}

.helper1:after {
  left: unset;
  right: -1px;
}

[class*="siDivScifi"].disabled,
[class*="siDivScifi"]:disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
}

/* Ripple Animation */
[class*="siDivScifi"] .ripple {
  inset: 0;
  position: absolute;
  overflow: hidden;
  -webkit-clip-path: var(--button-shape);
  clip-path: var(--button-shape);
}

[class*="siDivScifi"].disabled ripple,
[class*="siDivScifi"]:disabled ripple {
  display: none;
}

[class*="siDivScifi"] .ripple:after {
  content: "";
  --ripple-gradient: rgb(255, 255, 255);
  position: absolute;
  inset: 0 auto 0 -200%;
  width: 200%;
  transform: skewX(-20deg);
  opacity: 0.5;
  background-image: linear-gradient(
    to right,
    transparent,
    var(--ripple-gradient),
    transparent
  );
}

[mode*="light"] .ripple:after {
  --ripple-gradient: var(--border-color);
}

[class*="siDivScifi"]:hover .ripple:after {
  left: 200%;
  transition: 1.6s ease;
}

[class*="siDivScifi"] .ripple:before {
  content: "";
  --ripple-color: rgb(255, 255, 255);
  position: absolute;
  inset: 50%;
  transform: skewX(-10deg);
  border-radius: 100vh;
}

[mode*="light"] .ripple:before {
  --ripple-color: var(--border-color);
}

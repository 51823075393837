.badges {
  position: relative;
  left: -400px;
  top: -8px;
  display: flex;
  height: 56px;
}

.badges img {
  margin: 8px;
  width: 46px;
  height: 46px;
  border: 1px solid #5850b48f;
  border-radius: 5px;
  padding: 4px;
}

.fResource {
  font-size: 14px;
  padding: 8px 10px;
  border: solid 1px #5850b4;
  color: #ccc;
  border-radius: 4px;
}

.fContentRent {
  display: flex;
  justify-content: space-between !important;
}
.fResourceQtd {
  font-weight: bold;
}

.button {
  position: relative;
  left: 310px;
  margin-top: 0px;
}

.error {
  position: relative;
  top: 0px;
  margin-top: 30px;
}

.fLabel {
  // background-color: red;
  margin-top: 2.5%;
  margin-bottom: -12%;
}

.fSpace {
  margin-top: 10px;
}

.content {
  background-color: #00000056;
  // width: 400px;
  height: 100vh;
  padding: 20px;
  position: relative;
  border-top: #5850b488 4px solid;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 3%, 97% 0%);
  color: #fafafa;
}

.log {
  display: flex;
  width: 100%;
  background-color: #0a0915d7;
  padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  margin-bottom: 1px;
  font-size: 11px;
}

.type {
  color: #5850b4;
}

.text {
  width: 95%;
}

.confirm {
  width: 5%;
  cursor: pointer;
}

.logger {
  background: "transparent";
  user-select: none;
}

.open {
  z-index: 1800;
  background-color: #0a0915e1;
  position: absolute;
  padding-left: 28px;
  padding-top: 6px;
  bottom: 0px;
  height: 28px;
  width: 440px;
  cursor: pointer;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 60%, 97% 0%);
  color: #999;
}

.openBord {
  z-index: 1799;
  background-color: #5850b4;
  position: absolute;
  bottom: 0px;
  height: 30px;
  width: 440px;
  cursor: pointer;
  user-select: none;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 60%, 97% 0%);
}

.open:hover {
  background-color: #5850b4;
  user-select: none;
}

.notificationsCount {
  z-index: 1801;
  cursor: pointer;
  position: absolute;
  font-size: 14px;
  font-weight: 600;
  bottom: 8px;
  left: 340px;
  border-radius: 5px;
  border: #5850b4 1px solid;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #5850b4;
  user-select: none;
}

.display {
  z-index: 1500;
  padding: 10px;
  display: block;
  user-select: none;
  position: absolute;
  bottom: 14px;
  height: 65px;
  width: 440px;
  background-color: #00000056;
  color: #fafafa;
  border-top: #5850b488 4px solid;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 97% 0%);
}

.hide {
  display: none;
  user-select: none;
}

@media only screen and (max-width: 1440px) {
  .content {
    // background-color: #00000056;
    width: 300px;
    height: 100vh;
    padding: 20px;
    position: relative;
    border-top: #5850b488 4px solid;
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 3%, 97% 0%);
    color: #5850b4;
  }

  .logger {
    background: "transparent";
    user-select: none;
  }

  .open {
    z-index: 1800;
    background-color: #2b1c0ae1;
    position: absolute;
    padding-left: 28px;
    padding-top: 6px;
    bottom: 0px;
    height: 28px;
    width: 300px;
    cursor: pointer;
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 60%, 97% 0%);
    color: #999;
  }

  .openBord {
    z-index: 1799;
    background-color: #5850b4;
    position: absolute;
    bottom: 0px;
    height: 30px;
    width: 300px;
    cursor: pointer;
    user-select: none;
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 60%, 97% 0%);
  }

  .open:hover {
    background-color: #5850b4;
    user-select: none;
  }

  .notificationsCount {
    z-index: 1801;
    cursor: pointer;
    position: absolute;
    font-size: 14px;
    font-weight: 600;
    bottom: 8px;
    left: 240px;
    border-radius: 5px;
    border: #5850b4 1px solid;
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #5850b4;
    user-select: none;
  }

  .display {
    z-index: 1500;
    padding: 10px;
    display: block;
    user-select: none;
    position: absolute;
    bottom: 14px;
    height: 65px;
    width: 300px;
    background-color: #00000056;
    color: #5850b4;
    border-top: #5850b488 4px solid;
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 97% 0%);
  }

  .hide {
    display: none;
    user-select: none;
  }
}

@media only screen and (max-width: 1024px) {
  .content {
    display: none;
  }

  .open {
    display: none;
  }

  .openBord {
    display: none;
  }

  .notificationsCount {
    display: none;
  }

  .display {
    display: none;
  }
}

.table {
  position: relative;
  right: 30px;
  height: 375px;
  top: 10px;
  overflow: scroll;
  width: 100%;
}

.tableAliance {
  position: relative;
  right: 10px;
  height: 375px;
  top: 10px;
  overflow: scroll;
  width: 100%;
}

.go {
  position: relative;
  top: -30px;
  left: 200px;
}

.destak {
  font-size: 16px;
  font-weight: 600;
  color: #5850b4;
}

.alianceLogo {
  width: 32px;
  height: 32px;
}

.content {
  margin: 10px;
  width: 600px;
}

.messages {
  height: 80px;
}

.address {
  font-size: 10px;
  color: #5850b4;
}

.tableMissions {
  th,
  th {
    font-size: 11px;
    color: #ffffff;
    font-weight: bold;
    padding: 10px;
    position: sticky;
    top: 0;
    text-transform: uppercase;
    background-color: #0000004f;
  }

  td {
    padding: 10px;
    font-size: 11px;
    color: #ffffff;
    opacity: 0.7;
    border-top: 1px solid var(--card-border);
    height: 5px;
  }
}

.tableMissions tbody tr:hover {
  color: #ffffff;
  opacity: 1;
  background-color: #0000004f;
}

.tableMissions thead tr:hover {
  color: #ffffff;
  opacity: 1;
  background-color: #0000004f;
}

.load {
  color: #ffffff;
  margin-top: 5px;
}

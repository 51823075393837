.tbMembers tr td {
  font-size: 12px;
  padding: 1px;
  cursor: pointer;
}

.tbMembers {
  width: 110%;
  // margin-left: -11px;
}
.tbMembers th {
  background-color: #5850b4;
  padding: 2px;
  height: 25px;
}

// aceitar membro
.aceptMember {
  position: absolute;
  color: #f1f1f1;
  top: 3px;
  left: 0;
  padding: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.aceptMember:hover {
  scale: 1.1;
  color: #5850b4;
}

.notification {
  position: absolute;
  top: 1px;
  right: -10px;
  width: 15px;
  height: 15px;
  background-color: red;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  z-index: 1;
}
.tbMemberAction:hover {
  background-color: inherit !important;
}
.rank {
  padding-right: 5px !important;
}
.left {
  width: 30%;
  background-color: red;
  padding: 10px;
}
.right {
  width: 70%;
  background-color: lightgreen;
  padding: 10px;
}

.innerDiv {
  background-color: lightgray;
  margin-bottom: 10px;
  padding: 10px;
}

//  accordion
.accordion {
  border: 1px solid #5850b4;
  border-radius: 5px;
  background-color: red !important;
}

.accordionItem {
  border-bottom: 1px solid #5850b4;
}

.accordionHeader {
  background-color: transparent;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  color: #eee;
  font-weight: bold;
  width: 100%;
}

.accordionContent {
  display: none;
  padding: 10px;
  background-color: transparent;
}

.accordionContentBody {
  display: flex;
  flex-direction: column;
}

/* .outer {
    display: flex;
    flex-direction: column;
  } */

.content-msg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: #5850b4 solid 1px;
}

.msg-not-read {
  font-weight: bold;
  font-style: italic;
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.not-read {
  background-color: #5850b4;
  cursor: pointer;
}
.read {
  background-color: #008000;
  cursor: none;
}

.content-text {
  width: 95%;
  height: auto;
  color: #eee;

  /* background-color: #ccc; */
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.content-btn {
  width: 5%;
  color: #eee;
  height: auto;
  /* background-color: #aaa; */
  padding-top: 5px;
  padding-bottom: 5px;
}

.content-content {
  padding-left: 5px;
}

.contents-body {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
}

.accordion-item.active .accordion-header {
  background-color: #5850b4;
}

.accordion-item.active .accordion-content {
  display: block;
}

.accordion-avatar {
  width: 75px;
  height: 75px;
  background-color: #eee;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.accodion-avatar-content {
  width: 35px;
  height: 35px;
  background-color: #eee;
  border-radius: 1px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.title-content {
  color: #d87100;
}

.sub-title-content {
  color: #ccc;
}

.content-header {
  padding-left: 10px;
  width: 100%;
}

.accordionContent {
  display: none;
  padding: 10px;
  background-color: transparent;
}

.accordionContentBody {
  display: flex;
  flex-direction: column;
}

//invite
.addMember {
  position: absolute;
  color: #f1f1f1;
  top: 5px;
  right: 0;
  padding: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.addMember:hover {
  scale: 1.2;
  color: #5850b4;
}

.titlePage {
  position: absolute;
  color: #f1f1f1;
  top: 0;
  right: 37%;
  padding-top: 13px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.contentMembersList {
  height: 330px;
  overflow-y: auto;
}
.numberMembersBottom {
  display: fixed;
  // justify-content: flex-end;
  position: sticky;
  bottom: 0;
}

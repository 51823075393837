@import "mixins";

.component {
  display: block;
  font-weight: var(--normal);
  width: 100%;
  transition: opacity var(--transition);

  &.disabled {
    cursor: not-allowed;

    .label {
      opacity: 0.5;
    }
  }

  .indicator {
    opacity: 0;
    transition: opacity var(--transition);
  }

  &:hover .indicator {
    opacity: 0.5;
  }

  &.checked .indicator {
    opacity: 1;
  }
}

/* indicator */
.track {
  border: solid 1px var(--input-border);
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.indicator {
  background: var(--text);
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

/* reversed */
.reversed {
  justify-content: space-between;

  .track {
    border-color: var(--card-bg);
  }

  .indicator {
    background: var(--card-bg);
  }
}

.sideA {
  z-index: 1;
  position: absolute;
  top: 210px;
  right: 80px;
  width: 460px;
  padding: 12px;
  border: #bebbe256 1px solid;
  background-color: #0a0915c9;
  border-radius: 3px;
}

.sideB {
  z-index: 1;
  position: absolute;
  top: 210px;
  left: 80px;
  width: 460px;
  padding: 12px;
  background-color: #0a0915c9;
  border-radius: 3px;
}

.control {
  z-index: 1;
  position: absolute;
  top: 220px;
  right: -2px;
  width: 460px;
  padding: 12px;
  border: #bebbe256 2px solid;
  border-radius: 4px;
}

.title {
  color: #f1f1f1;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.players {
  width: 100%;
  height: 200px;
}

.playersB {
  width: 100%;
  min-height: 80px;
}

.player {
  padding: 4px;
  border: #bebbe256 1px solid;
  border-radius: 3px;
  background-color: #010916;
  width: 100%;
  float: none;
  margin-bottom: 5px;
}

.playerB {
  padding: 4px;
  border: #bebbe256 1px solid;
  border-radius: 3px;
  background-color: #010916;
  width: 100%;
  height: 80px;
  float: none;
  margin-bottom: 5px;
  margin-right: 4px;
}

.info {
  border: #bebbe256 1px solid;
  border-radius: 3px;
  background-color: #010916;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.playerName {
  position: relative;
  top: -6px;
}

.ships img {
  width: 70px;
}

.separator {
  height: 10px;
}

.logoAliance {
  padding: 6px;
}

.log {
  font-size: 10px;
  color: #35a2ff;
}

.label {
  padding: 0;
  margin: 0;
  font-size: 8px;
}

.labelGeralA {
  font-size: 9px;
  position: absolute;
  top: 440px;
  left: 42px;
}

.labelGeralD {
  font-size: 9px;
  position: absolute;
  top: 440px;
  left: 114px;
}

.numberGeralA {
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 450px;
  left: 42px;
}

.numberGeralD {
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 450px;
  left: 116px;
}

.labelGeralA2 {
  font-size: 9px;
  position: absolute;
  top: 120px;
  left: 42px;
}

.labelGeralD2 {
  font-size: 9px;
  position: absolute;
  top: 120px;
  left: 114px;
}

.numberGeralA2 {
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 130px;
  left: 42px;
}

.numberGeralD2 {
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 130px;
  left: 116px;
}

.transportNumber {
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  left: 382px;
  top: 452px;
  background-color: #010916a4;
  border: #061f42 1px solid;
  border-radius: 3px;
  width: 40px;
  text-align: center;
  padding: 1px;
}

.message {
  font-size: 14px;
  z-index: 1;
  text-align: center;
}

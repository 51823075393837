.color {
  --color: white;
  --background-color: #000000;
  --border-color: gray;
  --border-width: 1px;
  --border-cut: 8px;
}

.color {
  bottom: 80px;
  right: 20px;
  margin-bottom: 15px;
  color: var(--color) !important;
  border: 0 !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  justify-self: center !important;
  align-self: center !important;
  vertical-align: middle !important;
  appearance: none !important;
  box-sizing: border-box !important;
  text-decoration: none !important;
  position: relative !important;
  word-spacing: 3px !important;
  letter-spacing: 0.4px !important;
  cursor: pointer !important;
  transition: 0.3s !important;
  line-height: initial !important;
  height: 80px !important;
  width: 160px !important;
  white-space: nowrap !important;
  z-index: 1 !important;
  user-select: none !important;
  outline: 0 !important;
  padding: 20px 30px !important;
  background-color: transparent !important;
}

.color:after,
.color:before {
  content: "";
  position: absolute;
  inset: var(--border-width);
  background: var(--background-color);
  z-index: -1;
  clip-path: var(--button-shape);
  box-shadow: inset 0 0 25px -10px var(--border-color);
}

.color:before {
  inset: 0;
  background: var(--border-color);
}

.color:hover {
  color: var(--border-color) !important;
}

.selected {
  --color: white;
  --background-color: #000000;
  --border-color: #5850b4;
  --border-width: 1px;
  --border-cut: 8px;
}

.selected {
  bottom: 80px;
  right: 20px;
  color: var(--color) !important;
  margin-bottom: 15px;
  border: 0 !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  justify-self: center !important;
  align-self: center !important;
  vertical-align: middle !important;
  appearance: none !important;
  box-sizing: border-box !important;
  text-decoration: none !important;
  position: relative !important;
  word-spacing: 3px !important;
  letter-spacing: 0.4px !important;
  cursor: pointer !important;
  transition: 0.3s !important;
  line-height: initial !important;
  height: 80px !important;
  width: 160px !important;
  white-space: nowrap !important;
  z-index: 1 !important;
  user-select: none !important;
  outline: 0 !important;
  padding: 20px 30px !important;
  background-color: transparent !important;
}

.selected:after,
.selected:before {
  content: "";
  position: absolute;
  inset: var(--border-width);
  background: var(--background-color);
  z-index: -1;
  clip-path: var(--button-shape);
  box-shadow: inset 0 0 25px -10px var(--border-color);
}

.selected:before {
  inset: 0;
  background: var(--border-color);
}

.selected:hover {
  color: var(--border-color) !important;
}

.option img {
  width: 80px;
  filter: grayscale(100%);
}

.optionActive img {
  width: 80px;
  animation: show 1s;
}

@keyframes show {
  0% {
    filter: grayscale(100%);
  }
  100% {
    filter: grayscale(0%);
  }
}

@media only screen and (max-width: 1280px) {
  .color {
    height: 115px !important;
    width: 150px !important;
    margin-bottom: 10px;
  }

  .selected {
    height: 115px !important;
    width: 150px !important;
  }
}

@media only screen and (max-width: 1180px) {
  .color {
    height: 115px !important;
    width: 150px !important;
    margin-bottom: -25px;
  }

  .selected {
    height: 115px !important;
    width: 150px !important;
    margin-bottom: -25px;
  }
}

@import "mixins";

.list {
  max-height: 360px;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.item {
  padding: 12px 0;
}

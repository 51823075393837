@mixin border {
  background: var(--input-bg);
  border: solid 1px var(--input-border);
  border-radius: var(--border-radius);
  overflow: hidden;
}

@mixin padding {
  padding: 0 12px;
}

@mixin size {
  @include padding;
  height: var(--input-height);
}

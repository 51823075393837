.backbar {
  margin-top: 1px;
  width: 42vh;
  padding: 2px;
  color: #ccc;
}

.backicon {
  padding-left: 3px;
  padding-right: 3px;
  margin-top: 0;
  cursor: pointer;
}

.backtitle {
  color: #fff;
  font-weight: bold;
  // margin-left: 1%;
}

.itensBackBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputAliance {
  font-size: 14px;
  padding: 8px 10px;
  border: solid 1px #5850b4;
  color: #ccc;
  border-radius: 4px;
  // cursor: pointer;
}

.btnAliance {
  font-size: 14px;
  padding: 8px 10px;
  border: solid 1px #5850b4;
  color: #ccc;
  border-radius: 4px;
}

.btnExitRank {
  font-size: 14px;
  padding: 8px 10px;
  border: solid 1px #5850b4;
  color: #ccc;
  border-radius: 4px;
  width: 100%;
}

.gpBtnMembers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.labelAliance {
  margin-top: 2.5%;
  margin-bottom: -5%;
}

.textarea {
  font-size: 14px;
  padding: 8px 10px;
  border: solid 1px #5850b4;
  color: #ccc;
  border-radius: 4px;
  height: 100px;
}

.title {
  font-size: 1.1em;
  text-align: center;
  padding-bottom: 5px;
}

.divider {
  margin-top: -1%;
  border-top: #5850b4 solid 1px;
}

//form etapas

.form-step {
  display: none;
}

.form-step.active {
  display: block;
}

.formButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.colors {
  display: grid;
  grid-template-columns: repeat(7, 30px);
  grid-gap: 10px;
  justify-content: space-around;
}

.child {
  width: 100%;
  height: 30px;
  background-color: #ccc;
  border-radius: 50%;
}

.child:hover {
  scale: 1.2;
  border: #5850b4 solid 3px !important;
}

.logos {
  display: grid;
  grid-template-columns: repeat(4, 80px);
  grid-gap: 5px;
  justify-content: space-around;
  height: 300px;
  overflow-y: scroll !important;
}

.logo {
  width: 100%;
  height: 80px;
  background-color: transparent;
}

.logo:hover {
  scale: 1.1;
}

.logoSelected {
  border: 1px solid #5850b4;
}

.arrowUp {
  z-index: 999;
  position: absolute;
  top: 50px;
  left: 368px;
  cursor: pointer;
}

.arrowDown {
  z-index: 1;
  position: absolute;
  bottom: 80px;
  left: 368px;
  cursor: pointer;
}

// Minha Alianca (data)
.buttonMenu {
  position: relative;
  height: 34px !important;
  width: 120px !important;
  background-color: #24204b;
  border: 0;
  margin: 1px;
  margin-top: 5px;
  cursor: pointer;
  color: #f1f1f1;
  text-align: center;

  &:hover {
    background-color: #5850b4;
    color: #f1f1f1;
  }

  clip-path: polygon(0 10px, 0 34px, 140px 34px, 150px 24px, 150px 0, 10px 0);
}

.activeMenu {
  position: relative;
  height: 34px !important;
  width: 120px !important;
  border: 0;
  margin: 1px;
  margin-top: 5px;
  cursor: pointer;
  text-align: center;
  background-color: #5850b4;
  color: #f1f1f1;
  clip-path: polygon(0 10px, 0 34px, 140px 34px, 150px 24px, 150px 0, 10px 0);
}

//searc aliance
.tableAliance tr td {
  font-size: 12px;
  padding: 1px;
  cursor: pointer;
}

.tableAliance th {
  // background-color: #5850B4;
  padding: 2px;
  height: 25px;
  align-items: center;
}

.joinAliance:hover {
  color: #5850b4;
  scale: 1.2;
}

.searchContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}

.searchInput {
  padding-right: 30px;
}

.searchIcon {
  color: #f1f1f1;
  position: absolute;
  top: 60%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Efeito de esconder a lupa quando o campo estiver preenchido */
.searchInput:not(:placeholder-shown) + .searchIcon {
  display: none;
}

// radio button backbar
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  padding: 0px !important;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: #5850b4;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px #5850b4;
    }

    &:checked + .slider:before {
      transform: translateX(26px);
    }
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #5850b4;
    border-radius: 26px;
    transition: background-color 0.2s;
    cursor: pointer;

    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      border-radius: 50%;
      transition: transform 0.2s;
    }
  }
}

.areaSwitch {
  margin-right: -1%;
}

.switchLabel {
  position: relative;
  color: #ccc;
  // font-weight: bold;
  cursor: none;
  margin-top: 10px;
}

.warning {
  display: flex;
  justify-content: flex-start;
}

.warningContainer {
  margin-bottom: 15px;
}

.box {
  display: flex;
  align-items: center;
  background-color: #5850b4;
  color: #fff;
  font-weight: bold;
  font-size: 9px;
  position: relative;
  width: 30px;
  padding-left: 4px;
  padding-top: 2px;
  text-align: center;
}

.senders tr td {
  border-bottom: #5850b4 solid 1px;
  padding: 5px;
  cursor: pointer;
}

tr:hover {
  background-color: #5850b4;
}

.title {
  font-size: 1.1em;
  text-align: center;
  padding-bottom: 5px;
}

.conversatioBox {
  padding-top: 5px;
  height: 88%;
  display: flex;
  flex-direction: column;
  overflow: scroll !important;
}

.sendersBox {
  overflow: scroll !important;
  height: 88%;
}

.divider {
  border-top: #5850b4 solid 1px;
}

.msgSended {
  text-align: left;
  padding: 5px;
  font-style: italic;
  flex-direction: column;
}

.msgReceived {
  text-align: right;

  padding: 5px;
}

.send {
  flex: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10%;
}
.sendBlock {
  pointer-events: none;
}
.inputContainer input {
  flex: 1;
  border: none;
  background-color: transparent;
  padding: 0px;
  margin-left: -15px;
  font-size: 14px;
  height: 15px;
}

.inputContainer button {
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: #666;
  margin-left: 5px;
}

.inputContainer button:hover {
  color: #333;
  cursor: pointer;
}

.icon {
  color: #fff;
  transform: rotate(-45deg);
  animation: rotate 2s normal;
}

.nIcon {
  color: #fff;
  transform: rotate(45deg);
  animation: rotate 2s normal;
}

.container {
  display: flex;
  flex-direction: row;
  height: 500px;
}

.leftDiv {
  flex-basis: 30%;
  flex-grow: 0;
  padding: 10px;
  border-right: 1px solid #5850b4;
}

.rightDiv {
  display: inline;

  flex-basis: 70%;
  flex-grow: 0;
  padding: 10px;
  padding: 10px;
}

tr.dragged {
  background-color: lightblue;
  font-weight: bold;
}

.hours {
  color: #5850b4;
  font-size: 0.6em;
  font-weight: bold;
  margin-top: -2px;
  font-style: normal;
}

.info {
  color: #5850b4;
  font-size: 0.7em;
  font-weight: bold;
  margin-top: 5px;
  margin-right: -2px;
}

.checkicon {
  color: green;
  transform: scale(0.8);
}
/*search*/

.searchContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}

.searchInput {
  padding-right: 30px;
}

.searchIcon {
  color: #f1f1f1;
  position: absolute;
  top: 60%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Efeito de esconder a lupa quando o campo estiver preenchido */
.searchInput:not(:placeholder-shown) + .searchIcon {
  display: none;
}

/*Badge notification*/

.notification {
  margin-top: 5px;
  margin-right: -20px;
  width: 20px;
  height: 20px;
  background-color: red;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  z-index: 1;
}
